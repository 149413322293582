import { ResponseError, Subscription, SubscriptionApiInterface } from "@syadem/kairos-subscription-js";
import { AppStore } from "../store";
import { Success } from "../actionResult";
import { Service } from "./service";

interface Payload {
  organization: {
    name: string;
    address: string;
    city: string;
    zipCode: string;
    phoneNumber: string;
  };
  organizationId: string;
}

export class CreateSubscriptionService implements Service<Payload, Subscription> {
  constructor(
    private readonly store: AppStore,
    private readonly subscriptionApi: SubscriptionApiInterface,
  ) {}

  async call({ organization, organizationId }: Payload): Promise<Success<Subscription>> {
    let count = 0;

    while (true) {
      try {
        const subscription = await this.subscriptionApi.createSubscription(organizationId, { organization });

        this.store.setState((state) => ({
          ...state,
          subscriptionOrganizations: {
            ...(state.subscriptionOrganizations || {}),
            [organizationId]: {
              id: organizationId,
              invoices: [],
              defaultPaymentMethod: null,
              subscription,
            },
          },
        }));

        return {
          type: "success",
          value: subscription,
        };
      } catch (e) {
        if (e instanceof ResponseError && e.response.status === 404 && ++count < 5) {
          await new Promise((r) => setTimeout(r, 1000));
        } else {
          throw e;
        }
      }
    }
  }
}
