import {
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Alert,
  Chip,
  Grid2,
  Avatar,
} from "@mui/material";
import { theme } from "../../../layout/Theme";
import { useOrganization, useTeamsForOrganizationId } from "../../../../store";
import { useAppContext } from "../../../hooks/useAppContext";
import { useI18n } from "../../../hooks/useI18n";
import AddIcon from "@mui/icons-material/Add";
import { useAuthenticatedUser } from "../../../../store";
import { useSubscriptionOrganization } from "../../../../store";
import { SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import { useState } from "react";
import { NewTeamDialog } from "../../team/NewTeamDialog";
import { useQueries } from "../../../providers/Dependencies";

export function OrganizationTeams() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const organization = useOrganization(organizationId ?? undefined);
  const teams = useTeamsForOrganizationId(organizationId ?? undefined);
  const currentUser = useAuthenticatedUser();
  const subscriptionOrganization = useSubscriptionOrganization(organizationId ?? undefined);
  const { allOrganizationsQuery } = useQueries();

  const isActive =
    subscriptionOrganization &&
    (subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active ||
      subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing);

  const [openNewTeamDialog, setOpenNewTeamDialog] = useState(false);

  if (!organization) {
    return null;
  }

  const isAdmin = currentUser && organization.adminIds?.includes(currentUser.id);

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        mx: "auto",
        overflow: "hidden",
        backgroundColor: "background.paper",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        padding: 4,
      }}
      elevation={0}
    >
      <Grid2 container spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Typography variant="h6" color="neutral.700">
            {t("team.management")}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }} sx={{ display: "flex", justifyContent: { xs: "flex-start", sm: "flex-end" } }}>
          {isAdmin && (
            // TODO: Replace this with a regular button
            // For now, copy-pasting the button from HealthRecords.tsx
            // <Button
            //   variant="contained"
            //   startIcon={<AddIcon />}
            //   disabled={!isActive}
            //   onClick={() => setOpenNewTeamDialog(true)}
            // >
            //   {t("team.add")}
            // </Button>
            <Button
              onClick={() => setOpenNewTeamDialog(true)}
              startIcon={
                <Avatar
                  sx={{
                    background: theme.palette.primary[500],
                    width: { xs: 24, md: 32 },
                    height: { xs: 24, md: 32 },
                  }}
                >
                  <AddIcon fontSize="small" sx={{ color: "white" }} />
                </Avatar>
              }
              sx={{
                ".MuiButton-startIcon": {
                  minWidth: { xs: "40px", md: "56px" },
                  m: 0,
                },
                px: 1,
                pb: 1,
              }}
              disableElevation
            >
              <Typography color="primary" fontWeight="500" sx={{ fontSize: { xs: 14, md: 16 } }}>
                {t("team.add")}
              </Typography>
            </Button>
          )}
        </Grid2>
        <NewTeamDialog
          open={openNewTeamDialog}
          onClose={() => {
            console.log("CLOSE");
            setOpenNewTeamDialog(false);
          }}
          organizationId={organization.id}
          onSuccess={() => {
            // Refresh organization list
            allOrganizationsQuery.call();
            setOpenNewTeamDialog(false);
          }}
        />
      </Grid2>

      {!isActive && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {t("team.reactivateSubscriptionToAddTeam")}
        </Alert>
      )}

      {!isAdmin && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {t("team.contactAdminToAddTeams")}
        </Alert>
      )}

      <List>
        {teams.map((team) => (
          <ListItem
            key={team.id}
            sx={{
              mb: 1,
              border: `1px solid ${theme.palette.neutral[200]}`,
              borderRadius: 1,
              backgroundColor: "background.paper",
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "flex-start", sm: "center" },
                    }}
                  >
                    <Typography component="span">{team.presentationName}</Typography>
                    {!team.activated && (
                      <Chip
                        label={t("team.pendingTeamActivation")}
                        size="small"
                        color="warning"
                        sx={{
                          wordBreak: "break-word",
                          maxWidth: "100%",
                        }}
                      />
                    )}
                  </Box>
                  <Typography component="span">
                    {`${team.memberIds.length} ${t("team.members", {
                      smart_count: team.memberIds.length,
                    })}`}
                  </Typography>
                </Box>
              }
              secondary={
                <Box component="span" sx={{ mt: 1 }}>
                  <Typography component="span" variant="body2" color="text.secondary">
                    {team.address}, {team.zipCode} {team.city}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}

        {teams.length === 0 && (
          <Typography color="text.secondary" align="center" sx={{ py: 4 }}>
            {t("team.none")}
          </Typography>
        )}
      </List>
    </Paper>
  );
}
