import { Apis } from "../network/apis";
import { AppStore } from "../store";
import { AllOrganizationsQuery } from "./AllOrganizationsQuery";
import { AuthStateQuery } from "./authStateQuery";
import { CurrentAccountQuery } from "./CurrentAccountQuery";
import { GetVaccinationCertificateQuery } from "./getVaccinationCertificateQuery";
import { ProAllHealthRecordsQuery } from "./ProAllHealthRecordsQuery";
import { ProHealthRecordQuery } from "./ProHealthRecordQuery";
import { TeamHealthRecordQuery } from "./TeamHealthRecordQuery";
import { TeamHealthRecordsQuery } from "./TeamHealthRecordsQuery";
import { TeamMembersQuery } from "./TeamMembersQuery";

export interface Queries {
  allOrganizationsQuery: AllOrganizationsQuery;
  teamMembersQuery: TeamMembersQuery;
  currentAccountQuery: CurrentAccountQuery;
  authStateQuery: AuthStateQuery;
  getVaccinationCertificate: GetVaccinationCertificateQuery;
  proAllHealthRecordsQuery: ProAllHealthRecordsQuery;
  teamHealthRecordsQuery: TeamHealthRecordsQuery;
  proHealthRecordQuery: ProHealthRecordQuery;
  teamHealthRecordQuery: TeamHealthRecordQuery;
}

export const initQueries = ({ store, apis }: { store: AppStore; apis: Apis }): Queries => {
  return {
    allOrganizationsQuery: new AllOrganizationsQuery(store, apis.team.organizationApi),
    teamMembersQuery: new TeamMembersQuery(store, apis.team.teamApi),
    currentAccountQuery: new CurrentAccountQuery(store, apis.pro.professionalAccountApi),
    authStateQuery: new AuthStateQuery(store, apis.pro.professionalAccountApi),
    getVaccinationCertificate: new GetVaccinationCertificateQuery(apis.pro.kairosCertApi, apis.team.kairosCertApi),
    proAllHealthRecordsQuery: new ProAllHealthRecordsQuery(store, apis.pro.healthRecordApi),
    teamHealthRecordsQuery: new TeamHealthRecordsQuery(store, apis.team.healthRecordApi),
    proHealthRecordQuery: new ProHealthRecordQuery(store, apis.pro.healthRecordApi),
    teamHealthRecordQuery: new TeamHealthRecordQuery(store, apis.team.healthRecordApi),
  };
};
