import { PageLayout } from "../../../layout/PageLayout";
import { useI18n } from "../../../hooks/useI18n";
import { Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { useAuthenticatedUser, useOrganization, useSubscriptionOrganization } from "../../../../store";
import { useAppContext } from "../../../hooks/useAppContext";
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import { useCountryConfig } from "../../../hooks/useCountryConfig";

export function OrganizationSettings() {
  const { organizationId } = useAppContext();
  const organization = useOrganization(organizationId ?? undefined);

  if (!organization) {
    return null;
  }

  return (
    <PageLayout title={organization.name} tabs={<SettingsTabs />}>
      <Outlet />
    </PageLayout>
  );
}

function SettingsTabs() {
  const { t } = useI18n();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { pathname } = useLocation();

  // Check if the subscription tab should be shown
  const { subscription: subscriptionFeature } = useCountryConfig();
  const currentUser = useAuthenticatedUser();
  const { organizationId } = useAppContext();
  const organization = useOrganization(organizationId ?? undefined);
  const subscriptionOrganization = useSubscriptionOrganization(organization?.id);

  const isCurrentUserAdmin = currentUser && organization?.adminIds?.includes(currentUser.id);
  const isSubscriptionFeatureEnabled = subscriptionFeature != undefined;
  const isSubscriptionTypePaid =
    subscriptionOrganization?.subscription.product.id === subscriptionFeature?.defaultProductId;

  const shouldShowSubscriptionTab = isCurrentUserAdmin && isSubscriptionFeatureEnabled && isSubscriptionTypePaid;

  let currentTab = "settings/info";
  if (pathname.endsWith("/teams")) {
    currentTab = "settings/teams";
  } else if (shouldShowSubscriptionTab && pathname.endsWith("/subscription")) {
    currentTab = "settings/subscription";
  }

  return (
    <Tabs
      value={currentTab}
      sx={{
        backgroundColor: "none",
        border: "none",
        px: { xs: 0, md: 2 },
        ".MuiButtonBase-root": {
          padding: { xs: "0 8px", md: "0 16px" },
        },
        ".MuiTabScrollButton-root": {
          color: theme.palette.primary[500],
        },
      }}
      variant={breakpointSm ? "fullWidth" : "standard"}
    >
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("organization.settings.tabs.info")}
          </Typography>
        }
        value="settings/info"
        to="info"
        component={RouterLink}
      />
      {shouldShowSubscriptionTab && (
        <Tab
          label={
            <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
              {t("organization.settings.tabs.subscription")}
            </Typography>
          }
          value="settings/subscription"
          to="subscription"
          component={RouterLink}
        />
      )}
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("organization.settings.tabs.teams")}
          </Typography>
        }
        value="settings/teams"
        to="teams"
        component={RouterLink}
      />
    </Tabs>
  );
}
