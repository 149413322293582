import Polyglot from "node-polyglot";
import { DomainHealthRecord } from "../domain/HealthRecord";
import { User } from "../domain/user";
import { formatFirstName } from "./string";

export function isCurrentOwner(healthRecord: DomainHealthRecord | undefined, currentUser: User | undefined) {
  return healthRecord && currentUser && healthRecord.ownerId == currentUser.id;
}

export function isSharedToPatient(healthRecord: DomainHealthRecord | undefined) {
  return healthRecord?.ownerReference?.type === "citizen";
}

export function isSharedToOtherProOrTeam(healthRecord: DomainHealthRecord | undefined) {
  if (healthRecord === undefined) return false;

  return healthRecord.accessibleByProfessionalIds.length + healthRecord.accessibleByTeamIds.length > 1;
}

export function getFullName({
  customaryName,
  firstNames,
  lastName,
  gender,
  t,
}: {
  customaryName?: string | null;
  firstNames: string;
  lastName: string;
  gender: string;
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string;
}) {
  if (customaryName && customaryName !== lastName) {
    const birthName = `(${t(`common.dates.born.${gender === "M" ? "male" : "female"}`)} ${lastName.toUpperCase()})`;
    return `${customaryName.toUpperCase()} ${formatFirstName(firstNames)} ${birthName}`;
  } else {
    return `${lastName.toUpperCase()} ${formatFirstName(firstNames)}`;
  }
}
