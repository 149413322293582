import { HealthRecordApi } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

export class TeamHealthRecordQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call(organizationId: string, teamId: string, id: string) {
    const { healthRecord } = await this.healthRecordApi.getHealthRecord(organizationId, teamId, id);

    this.store.setState((state) => ({
      healthRecords: { ...state.healthRecords, [healthRecord.id]: healthRecord },
    }));
  }
}
