import { UserManager } from "oidc-client-ts";
import { Service, ServicePayload } from "./service";
import { RedirectToLiberalOrTeamService } from "./RedirectToLiberalOrTeamService";
import { ShareWithCitizenByMailService } from "./ShareWithCitizenByMailService";
import { UpdateProfessionalAccountVisibilityService } from "./UpdateProfessionalAccountVisibilityService";
import { UpdateTeamVisibilityService } from "./UpdateTeamVisibilityService";
import { CreateTeamService } from "./CreateTeamService";
import { CreateOrganizationService } from "./CreateOrganizationService";
import { Apis } from "../network/apis";
import { CreateHealthRecordService } from "./createHealthRecordService";
import { AppStore } from "../store";
import { SignInService } from "./signInService";
import { SignOutService } from "./signOutService";
import { EditUserService } from "./editUserService";
import { Queries } from "../queries";
import { BuildPscAccountLinkingUrlService } from "./buildPscAccountLinkingUrlService";
import { CreateSubscriptionService } from "./CreateSubscriptionService";
import { AppRouter } from "../appConfig";

type ServiceCollection = {
  // We don't care about the return type of the service here
  [key: string]: Service<ServicePayload, any>;
};

export interface Services extends ServiceCollection {
  signIn: SignInService;
  signOut: SignOutService;
  editUser: EditUserService;
  redirectToLiberalOrTeam: RedirectToLiberalOrTeamService;
  shareWithCitizenByMail: ShareWithCitizenByMailService;
  updateProfessionalAccountVisibility: UpdateProfessionalAccountVisibilityService;
  updateTeamVisibility: UpdateTeamVisibilityService;
  createTeam: CreateTeamService;
  createOrganization: CreateOrganizationService;
  createHealthRecord: CreateHealthRecordService;
  buildPscAccountLinkingUrl: BuildPscAccountLinkingUrlService;
}

export function initServices({
  store,
  oidcManager,
  router,
  apis,
  queries,
  createSubscription,
}: {
  store: AppStore;
  oidcManager: UserManager;
  router: AppRouter;
  apis: Apis;
  queries: Queries;
  createSubscription?: CreateSubscriptionService;
}): Services {
  return {
    signIn: new SignInService(store, window, oidcManager, router, queries.authStateQuery),
    signOut: new SignOutService(window, oidcManager),
    editUser: new EditUserService(window, oidcManager),
    redirectToLiberalOrTeam: new RedirectToLiberalOrTeamService(router, localStorage, apis.team.organizationApi),
    shareWithCitizenByMail: new ShareWithCitizenByMailService(apis.pro.recordSharingApi, apis.team.recordSharingApi),
    updateProfessionalAccountVisibility: new UpdateProfessionalAccountVisibilityService(
      store,
      apis.pro.professionalAccountApi,
    ),
    updateTeamVisibility: new UpdateTeamVisibilityService(store, apis.team.teamApi),
    createTeam: new CreateTeamService(store, apis.team.teamApi),
    createOrganization: new CreateOrganizationService(store, apis.team.organizationApi, createSubscription),
    createHealthRecord: new CreateHealthRecordService(apis.pro.healthRecordApi, apis.team.healthRecordApi),
    buildPscAccountLinkingUrl: new BuildPscAccountLinkingUrlService(window, oidcManager),
  };
}
