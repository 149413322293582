import { Paper, Typography, Box, Grid } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { useOrganization } from "../../../../store";
import { useAppContext } from "../../../hooks/useAppContext";
import { useI18n } from "../../../hooks/useI18n";

export function OrganizationInfo() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const organization = useOrganization(organizationId ?? undefined);

  if (!organization) {
    return null;
  }

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        mx: "auto",
        overflow: "hidden",
        backgroundColor: "background.paper",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        padding: 4,
      }}
      elevation={0}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {t("organization.name")}
            </Typography>
            <Typography variant="body1">{organization.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {t("organization.phoneNumber")}
            </Typography>
            {!organization.phoneNumber ? (
              <Typography variant="body1" sx={{ fontStyle: "italic", color: "text.disabled" }}>{t("organization.noPhoneNumber")}</Typography>
            ) : (
              <Typography variant="body1">{organization.phoneNumber}</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {t("organization.address")}
            </Typography>
            {!organization.address && !organization.zipCode && !organization.city ? (
              <Typography variant="body1" sx={{ fontStyle: "italic", color: "text.disabled" }}>
                {t("organization.noAddress")}
              </Typography>
            ) : (
              <Typography variant="body1">
                {organization.address}
                <br />
                {organization.zipCode} {organization.city}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
