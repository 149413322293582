import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { City, Professional, Team } from "@syadem/ariane-js";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CityAndProAutocomplete } from "../../../components/CityAndProAutocomplete";
import { StyledCheckbox } from "../../../components/mui/StyledCheckbox";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";

export function ShareWithProForm({ onSubmitSuccessful }: { onSubmitSuccessful: () => void }) {
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const { id: healthRecordId } = useParams() as { id: string };
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const [city, setCity] = useState<City | undefined>(undefined);
  const [cityMissing, setCityMissing] = useState<boolean>(false);
  const [professionalOrTeam, setProfessionalOrTeam] = useState<Professional | Team | undefined>(undefined);
  const [professionalMissing, setProfessionalMissing] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [shareSuccessful, setShareSuccessful] = useState(false);

  // Reset checkbox on professional change
  useEffect(() => {
    setCheckbox(false);
  }, [professionalOrTeam]);

  const resetHandler = useCallback(() => {
    setCheckbox(false);
    setCheckboxError(false);
    setCity(undefined);
    setCityMissing(false);
    setProfessionalOrTeam(undefined);
    setProfessionalMissing(false);
    setShareSuccessful(false);
    setErrorMessage(undefined);
  }, []);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      (async () => {
        if (checkbox !== true) {
          setCheckboxError(true);
          return;
        }
        if (city === undefined) {
          setCityMissing(true);
          return;
        }
        if (professionalOrTeam === undefined) {
          setProfessionalMissing(true);
          return;
        }
        try {
          setIsSending(true);
          setErrorMessage(undefined);
          let payload = {};

          if (isProfessional(professionalOrTeam)) {
            payload = {
              professional: {
                externalId: professionalOrTeam.rpps,
                firstName: professionalOrTeam.first_name,
                lastName: professionalOrTeam.last_name,
              },
            };
          } else if (isTeam(professionalOrTeam)) {
            payload = {
              team: {
                id: professionalOrTeam.id,
              },
            };
          }

          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.healthRecordApi.share(organizationId, teamId, healthRecordId, payload);
          } else {
            await apis.pro.healthRecordApi.share(healthRecordId, payload);
          }

          setShareSuccessful(true);
          onSubmitSuccessful();
        } catch (_e) {
          setErrorMessage(t("common.alerts.alert_notification"));
        } finally {
          setIsSending(false);
        }
      })();
    },
    [apis, checkbox, city, healthRecordId, onSubmitSuccessful, professionalOrTeam, t, organizationId, teamId],
  );

  const cityChangeHandler = useCallback((newCity?: City) => {
    setCity(newCity);
    setCityMissing(newCity === undefined);
    setErrorMessage(undefined);
  }, []);

  const professionalChangeHandler = useCallback((newProfessional?: Professional | Team) => {
    setProfessionalOrTeam(newProfessional);
    setProfessionalMissing(newProfessional === undefined);
    setErrorMessage(undefined);
  }, []);

  return (
    <Box sx={{ backgroundColor: "white", p: { xs: 2, md: 3 }, border: `1px solid ${theme.palette.neutral[300]}` }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: -1 }}>
        <ChevronRightIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
        <Typography variant="h6">{t("share.with_a_health_professional")}</Typography>
      </Box>
      <Box id="form" sx={{ ml: { xs: 1, md: 3 } }}>
        <form onSubmit={submitHandler}>
          {shareSuccessful ? (
            <Alert severity="success">
              <AlertTitle>{t("health_records.sharing_request_sent")}</AlertTitle>
            </Alert>
          ) : (
            <>
              <Typography variant="body2" component="span">
                {t("share.browse_professional_directory")}
              </Typography>

              <Grid2 container spacing={1} sx={{ marginBottom: 3, marginTop: 1 }}>
                <CityAndProAutocomplete
                  city={city}
                  cityError={cityMissing}
                  onCityChange={cityChangeHandler}
                  professional={professionalOrTeam}
                  professionalError={professionalMissing}
                  onProfessionalChange={professionalChangeHandler}
                  onArianeError={(e) => {
                    if (e) {
                      setErrorMessage(t("common.alerts.alert_notification"));
                    }
                  }}
                />
              </Grid2>
              {professionalOrTeam ? (
                <>
                  {isProfessional(professionalOrTeam) ? (
                    <ResultCard
                      primary={`${professionalOrTeam.first_name} ${professionalOrTeam.last_name}`}
                      secondary={
                        professionalOrTeam.rpps
                          ? `${t("healthProfessional.rppsNumber")}: ${professionalOrTeam.rpps}`
                          : t("healthProfessional.noRppsNumber")
                      }
                      icon={<PersonIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />}
                      rightContent={
                        <>
                          {professionalOrTeam.address}
                          <br />
                          {`${professionalOrTeam.zip_code} ${professionalOrTeam.city?.toUpperCase()}`}
                        </>
                      }
                    />
                  ) : (
                    <>
                      <Alert
                        severity="info"
                        sx={{
                          my: 2,
                          bgcolor: theme.palette.primary[100],
                          color: theme.palette.primary[600],
                          border: `solid 1px ${theme.palette.primary[200]}`,
                          ".MuiAlert-icon": {
                            color: theme.palette.primary[600],
                          },
                        }}
                      >
                        {t("share.share_with_team_disclaimer")}
                      </Alert>
                      <ResultCard
                        primary={professionalOrTeam.name}
                        secondary={`${t("team.identificationNumber")}: ${professionalOrTeam.id}`}
                        icon={<GroupsIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />}
                        rightContent={
                          <>
                            {professionalOrTeam.address}
                            <br />
                            {`${professionalOrTeam.zip_code} ${professionalOrTeam.city.toUpperCase()}`}
                          </>
                        }
                      />
                    </>
                  )}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <StyledCheckbox>
                      <FormControlLabel
                        sx={{
                          color: checkboxError ? "error.main" : "text.primary",
                        }}
                        control={
                          <Checkbox
                            required
                            name="vaccineReminder"
                            checked={checkbox}
                            data-testid="consentCheckbox"
                            onChange={(_e, checked) => {
                              setCheckbox(checked);
                              setCheckboxError(checked !== true);
                            }}
                          />
                        }
                        label={<Typography variant="body2">{t("health_records.pro_attestation")}</Typography>}
                      />
                    </StyledCheckbox>
                  </Box>
                </>
              ) : null}
              {errorMessage ? (
                <Alert data-testid="alert" severity="error" sx={{ marginY: 3 }}>
                  {errorMessage}
                </Alert>
              ) : null}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 6 }}>
            {!shareSuccessful ? (
              <LoadingButton
                data-testid="submitButton"
                type="submit"
                variant="contained"
                loading={isSending}
                disabled={isSending || checkbox !== true || !professionalOrTeam || !city}
                disableElevation
                startIcon={<ShareIcon />}
              >
                {t("health_records.share")}
              </LoadingButton>
            ) : (
              <Button
                size="large"
                variant="contained"
                onClick={resetHandler}
                disableElevation
                startIcon={<ShareIcon />}
              >
                {t("health_records.search_other_pro")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

const ResultCard = ({
  primary,
  secondary,
  rightContent,
  icon,
}: {
  primary: string;
  secondary: string;
  rightContent: ReactNode;
  icon: any;
}) => {
  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      sx={{
        marginTop: 3,
        marginBottom: 2,
        border: `solid 1px ${theme.palette.neutral[400]}`,
      }}
      elevation={0}
    >
      <CardContent>
        <Box display="flex">
          <Box display="flex" sx={{ alignItems: "center", mr: { xs: 2, md: 4 } }}>
            <Avatar
              sx={{ background: theme.palette.primary[100], height: { xs: 56, md: 72 }, width: { xs: 56, md: 72 } }}
            >
              {icon}
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box>
              <Typography variant={breakpointMd ? "h6" : "h5"} component="div">
                {primary}
              </Typography>
              <Typography color="text.secondary" variant={breakpointMd ? "body2" : "body1"}>
                {secondary}
              </Typography>
            </Box>
            <Divider
              orientation={breakpointMd ? "horizontal" : "vertical"}
              sx={{ mx: { xs: 0, md: 3 }, my: { xs: 2, md: 0 }, width: { xs: "100%", md: "1px" } }}
            />
            <Typography variant="body2">{rightContent}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

function isProfessional(object: object): object is Professional {
  return "first_name" in object;
}
function isTeam(object: object): object is Team {
  return "name" in object;
}
