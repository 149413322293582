import { Buffer } from "buffer";

// Disabling eslint is unavoidable since webcrypto can only be used like this
/* eslint-disable @typescript-eslint/no-require-imports */
const crypto: Crypto =
  typeof process !== "undefined" && typeof process?.versions?.node === "string"
    ? (require("node:crypto").webcrypto as Crypto)
    : window.crypto;

export async function buildAccountLinkingUrl(
  authServerUrl: URL,
  realm: string,
  clientId: string,
  provider: string,
  sessionState: string,
  nonce: string,
  redirectUri: string,
): Promise<URL> {
  const url = new URL(`/realms/${realm}/broker/pro-sante-connect/link`, authServerUrl);
  url.searchParams.set("client_id", clientId ?? "");
  url.searchParams.set("redirect_uri", redirectUri);

  url.searchParams.set("nonce", nonce);
  url.searchParams.set(
    "hash",
    Buffer.from(
      await crypto.subtle.digest("SHA-256", new TextEncoder().encode(nonce + sessionState + clientId + provider)),
    )
      .toString("base64")
      .replaceAll("+", "-")
      .replaceAll("/", "_"),
  );

  return url;
}
