import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import { JSX } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";

export function NotFound(): JSX.Element {
  const { t } = useI18n();
  return (
    <PageLayout title={t("common.errors.title", { statusCode: "404" })}>
      <Container sx={{ marginTop: "35vh" }}>
        <CssBaseline />
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: "2em",
            fontWeight: 300,
            "& strong": {
              fontWeight: 400,
            },
            marginY: "1em",
          }}
        >
          <strong>{t("common.errors.title", { statusCode: "404" })}</strong>
          {`: ${t("common.errors.404")}`}
        </Typography>
        <Box textAlign="center">
          <Button component={RouterLink} variant="contained" to="/" disableElevation>
            {t("common.interface.back_to_home")}
          </Button>
        </Box>
      </Container>
    </PageLayout>
  );
}
