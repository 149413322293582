import { useEffect, useState } from "react";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { DomainHealthRecord } from "../../domain/HealthRecord";

export function useAllUnsharedHealthRecords(): {
  isLoading: boolean;
  unsharedHealthRecords?: DomainHealthRecord[];
  error?: Response | Error;
  fetchUnsharedHealthRecords: () => void;
} {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [unsharedHealthRecords, setUnsharedHealthRecords] = useState<DomainHealthRecord[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUnsharedHealthRecords = () => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { unsharedHealthRecords } = await apis.team.unsharedHealthRecordApi.allUnsharedHealthRecords(
            organizationId,
            teamId,
          );
          setUnsharedHealthRecords(unsharedHealthRecords);
        } else {
          const { unsharedHealthRecords } = await apis.pro.unsharedHealthRecordApi.allUnsharedHealthRecords();
          setUnsharedHealthRecords(unsharedHealthRecords);
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  };

  useEffect(fetchUnsharedHealthRecords, [apis, organizationId, teamId]);

  return { isLoading, unsharedHealthRecords, error, fetchUnsharedHealthRecords };
}
