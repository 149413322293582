import { AppStore } from "../store";
import { ProfessionalAccountApiInterface, ResponseError } from "@syadem/kairos-pro-js";

export class AuthStateQuery {
  constructor(
    private readonly store: AppStore,
    private readonly professionalAccountApi: ProfessionalAccountApiInterface,
  ) {}

  async call() {
    try {
      const professionalAccount = (await this.professionalAccountApi.showCurrentProfessionalAccount())
        .professionalAccount;

      this.store.setState((state) => {
        if (state.authState.type == "signed-in") {
          return {
            authState: {
              ...state.authState,
              user: {
                ...state.authState.user,
                verified: true,
                firstName: professionalAccount.firstName ?? undefined,
                lastName: professionalAccount.lastName ?? undefined,
                phone: professionalAccount.phone ?? undefined,
                zipCode: professionalAccount.zipCode ?? undefined,
                address: professionalAccount.address ?? undefined,
                city: professionalAccount.city ?? undefined,
                rpps: professionalAccount.rpps ?? undefined,
              },
            },
          };
        } else {
          return state;
        }
      });
    } catch (e) {
      if ((e instanceof Response && e.status === 403) || (e instanceof ResponseError && e.response.status === 403)) {
        this.store.setState((state) => {
          if (state.authState.type == "signed-in") {
            return {
              authState: {
                ...state.authState,
                user: {
                  ...state.authState.user,
                  verified: false,
                },
              },
            };
          } else {
            return state;
          }
        });
      } else {
        this.store.setState({ authState: { type: "error", error: e as Error } });
      }
    }
  }
}
