import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { SharingRequest } from "@syadem/kairos-pro-js";
import { useState } from "react";
import { Link } from "react-router-dom";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import HealthRecordListItemContent from "../components/shared/HealthRecordListItemContent";
import { ListItemDeleteButton } from "../components/shared/ListItemDeleteButton";
import { useI18n } from "../hooks/useI18n";
import { usePendingSharingRequests } from "../hooks/useSharingRequests";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { useApis } from "../providers/Dependencies";
import { DomainHealthRecord } from "../../domain/HealthRecord";

export function SharingRequests() {
  const { t } = useI18n();
  const { sharingRequests, fetchSharingRequests, isLoading } = usePendingSharingRequests();
  const apis = useApis();

  const [actionSucceeded, setActionSucceeded] = useState<boolean | undefined>(undefined);

  const handleAccept = async (healthRecord: DomainHealthRecord, sharingRequest: SharingRequest) => {
    try {
      // TODO: Wrap this inside a service
      await apis.pro.sharingRequestApi.acceptSharingRequest(healthRecord.id, sharingRequest.id);
      setActionSucceeded(true);
    } catch (_error) {
      setActionSucceeded(false);
    } finally {
      fetchSharingRequests();
    }
  };

  const reloadCallback = () => {
    setActionSucceeded(true);
    fetchSharingRequests();
  };

  const resetActionSucceeded = () => {
    setActionSucceeded(undefined);
  };

  return (
    <PageLayout title={t("common.interface.pending_sharing_requests")}>
      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        {isLoading && <LinearProgress variant="query" />}

        {sharingRequests ? (
          sharingRequests.length > 0 ? (
            <SharingRequestsList
              sharingRequests={sharingRequests}
              onAccept={handleAccept}
              reloadCallback={reloadCallback}
            />
          ) : (
            !isLoading && (
              <EmptyListPlaceholder
                title={t("sharing_requests.status")}
                icon={<PersonAddIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
                button={
                  <Button
                    size="large"
                    variant="contained"
                    component={Link}
                    to={`/liberal/health-records`}
                    disableElevation
                  >
                    {t("sharing_requests.back_to_health_records")}
                  </Button>
                }
              >
                {t("sharing_requests.notification")}
              </EmptyListPlaceholder>
            )
          )
        ) : null}

        <Snackbar
          open={actionSucceeded === false}
          autoHideDuration={6000}
          onClose={resetActionSucceeded}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{t("sharing_requests.error")}</Alert>
        </Snackbar>
        <Snackbar
          open={actionSucceeded === true}
          autoHideDuration={6000}
          onClose={resetActionSucceeded}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="info">{t("sharing_requests.success")}</Alert>
        </Snackbar>
      </Paper>
    </PageLayout>
  );
}

interface SharingRequestsListProps {
  sharingRequests: SharingRequest[];
  onAccept: (healthRecord: DomainHealthRecord, sharingRequest: SharingRequest) => Promise<void>;
  reloadCallback: () => void;
}

function SharingRequestsList(props: SharingRequestsListProps) {
  const { sharingRequests, onAccept, reloadCallback } = props;
  const apis = useApis();
  const { t } = useI18n();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <List sx={{ width: "100%" }}>
      {sharingRequests.map((sharingRequest, index) => {
        const healthRecord: DomainHealthRecord | null | undefined = sharingRequest.healthRecord;

        return (
          healthRecord && (
            <ListItem
              key={sharingRequest.id}
              sx={{ px: 0, py: 0.5 }}
              secondaryAction={
                <Box display="flex">
                  {breakpointSm ? (
                    <Tooltip title={t("common.cta.accept")} arrow>
                      <IconButton size="small" onClick={() => onAccept(healthRecord, sharingRequest)}>
                        <CheckIcon fontSize="medium" color="success" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => onAccept(healthRecord, sharingRequest)}
                      size={breakpointMdUp ? "medium" : "small"}
                      startIcon={<CheckIcon fontSize="small" />}
                      color="success"
                      sx={{ color: "white", mr: 1 }}
                      disableElevation
                    >
                      {t("common.cta.accept")}
                    </Button>
                  )}

                  <ListItemDeleteButton
                    deleteRecord={() =>
                      apis.pro.sharingRequestApi.rejectSharingRequest(
                        sharingRequest.healthRecord?.id as string,
                        sharingRequest.id,
                      )
                    }
                    refreshCallback={reloadCallback}
                    title={t("common.cta.reject")}
                    modalTitle={t("sharing_requests.reject")}
                    modalContent={t("sharing_requests.reject_validation")}
                    icon={<ClearIcon color={breakpointSm ? "error" : "inherit"} />}
                    buttonWidth="110px"
                    type={breakpointSm ? "icon" : "button"}
                  />
                </Box>
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  p: 2,
                  border: `solid 1px ${theme.palette.neutral[200]}`,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <HealthRecordListItemContent
                  healthRecord={healthRecord}
                  icon={<PersonAddIcon sx={{ color: theme.palette.primary[500] }} />}
                  testId={`sharingHealthRecordContent${index}`}
                />
              </Box>
            </ListItem>
          )
        );
      })}
    </List>
  );
}
