import { Box, List, Typography, useMediaQuery } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { DomainHealthRecord } from "../../domain/HealthRecord";
import { groupVaccinationActsByDisease } from "../../utils/vaccinationActs";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import { VaccinationActListItem } from "./VaccinationActListItem";

function VaccinationActsByDiseaseGroups({
  vaccinationActs,
  daphne,
  certificators,
  reloadCallback,
  healthRecord,
  showActions = true,
}: {
  vaccinationActs: ProVaccinationAct[] | TeamVaccinationAct[];
  daphne: Daphne;
  certificators?: (Professional | Team)[];
  healthRecord: DomainHealthRecord;
  reloadCallback?: () => void;
  showActions?: boolean;
}) {
  const { locale } = useI18n();
  const groups = groupVaccinationActsByDisease(vaccinationActs, daphne, locale);
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {Object.entries(groups).map(([groupName, vaccinationActs]) => (
        <Box key={groupName} sx={{ mb: 1 }}>
          <Typography variant={breakpointSm ? "body1" : "h6"} fontWeight={"500"}>
            {groupName}
          </Typography>
          <List sx={{ width: "100%", pt: 1 }}>
            {vaccinationActs.map((vaccinationAct) => (
              <VaccinationActListItem
                vaccinationAct={vaccinationAct}
                healthRecord={healthRecord}
                showActions={showActions}
                refreshCallback={reloadCallback}
                key={vaccinationAct.id}
                daphne={daphne}
                certificators={certificators}
                displayDiseases={false}
              />
            ))}
          </List>
        </Box>
      ))}
    </Box>
  );
}

export default VaccinationActsByDiseaseGroups;
