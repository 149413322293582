import { useCallback, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { Box, Paper, Typography } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { LoadingButton } from "@mui/lab";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useCountryConfig } from "../../../hooks/useCountryConfig";
import { SubscriptionComponentProps } from "../settings/OrganizationSubscription";

export function PaymentMethod(props: SubscriptionComponentProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage } = props;
  const { t } = useI18n();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { subscription } = useCountryConfig();

  const navigateToCheckoutUrl = useCallback(async () => {
    setErrorDisplayed(false);
    setSuccessDisplayed(false);
    setIsSubmitting(true);

    try {
      const url = await subscription?.queries.subscriptionCheckoutUrlQuery.call({
        organizationId: subscriptionOrganization.id,
      });
      window.open(url, "_self");
    } catch (_e) {
      setErrorMessage(t("common.alerts.alert_notification"));
      setErrorDisplayed(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    subscriptionOrganization,
    setErrorDisplayed,
    setSuccessDisplayed,
    setErrorMessage,
    setIsSubmitting,
    subscription,
    t,
  ]);

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        marginTop: 1,
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2,
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <CreditCardIcon sx={{ color: theme.palette.neutral["600"] }} />
        <Typography fontWeight={500} color={theme.palette.neutral["600"]} fontSize={18} ml={1}>
          {t("subscriptions.paymentInformations")}
        </Typography>
      </Box>
      <Typography color={theme.palette.neutral["600"]}>
        {subscriptionOrganization.defaultPaymentMethod
          ? t("subscriptions.cardDetails", {
              brand: subscriptionOrganization.defaultPaymentMethod.brand,
              lastFourDigits: subscriptionOrganization.defaultPaymentMethod.lastFourDigits,
              expiration: `${subscriptionOrganization.defaultPaymentMethod.expirationMonth.toString().padStart(2, "0")}/${subscriptionOrganization.defaultPaymentMethod.expirationYear}`,
            })
          : t("subscriptions.noPaymentMethod")}
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <LoadingButton variant="outlined" onClick={navigateToCheckoutUrl} loading={isSubmitting}>
          {subscriptionOrganization.defaultPaymentMethod
            ? t("subscriptions.updatePaymentMethod")
            : t("subscriptions.addPaymentMethod")}
        </LoadingButton>
      </Box>
    </Paper>
  );
}
