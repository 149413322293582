import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Alert, Avatar, Box, Button, Grid2, LinearProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DomainHealthRecord } from "../../domain/HealthRecord";
import { LAST_HOME_PAGE_KEY } from "../../services/RedirectToLiberalOrTeamService";
import { useProHealthRecords } from "../../store";
import { dayjsWithLocale } from "../../utils/dayjs";
import { replaceSpecialChars } from "../../utils/string";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import HealthRecordSearchBar from "../components/shared/HealthRecordSearchBar";
import HealthRecordsList from "../components/shared/HealthRecordsList";
import { useFetchAllHealthRecords } from "../hooks/useFetchAllHealthRecords";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";

const NoHealthRecords = () => {
  const { t } = useI18n();
  return (
    <EmptyListPlaceholder
      title={t("patients.none")}
      icon={<AssignmentIndIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
      button={
        <Button size="large" component={Link} to="new" variant="contained" startIcon={<AddIcon />} disableElevation>
          {t("patients.add")}
        </Button>
      }
    >
      {t("health_records.create_or_access")}
    </EmptyListPlaceholder>
  );
};

export default function HealthRecords() {
  const { t, locale } = useI18n();
  const { error, fetchHealthRecords } = useFetchAllHealthRecords();
  const healthRecords = useProHealthRecords();

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [filteredHealthRecords, setFilteredHealthRecords] = useState<DomainHealthRecord[] | undefined>(healthRecords);

  useEffect(() => {
    if (healthRecords === undefined || searchValue === undefined || searchValue.trim() === "") {
      setFilteredHealthRecords(healthRecords);
    } else {
      const fuse = new Fuse(healthRecords, {
        keys: [
          { name: "firstNames", getFn: (record) => replaceSpecialChars(record.firstNames) },
          { name: "lastName", getFn: (record) => replaceSpecialChars(record.lastName) },
          {
            name: "firstNameLastname",
            getFn: (record) => replaceSpecialChars(`${record.firstNames} ${record.lastName}`),
          },
          {
            name: "lastnameFirstName",
            getFn: (record) => replaceSpecialChars(`${record.lastName} ${record.firstNames}`),
          },
          {
            name: "customaryName",
            getFn: (record) => (record.customaryName && replaceSpecialChars(record.customaryName)) || "",
          },
          {
            name: "externalId",
            getFn: (record) => record.externalId || "",
          },
          {
            name: "externalIdWithoutSpaces",
            getFn: (record) => record.externalId?.replaceAll(" ", "") || "",
          },
          {
            name: "birthDate",
            getFn: (record) => dayjsWithLocale(record.birthDate, locale),
          },
        ],
        threshold: 0.1,
      });

      setFilteredHealthRecords(fuse.search(replaceSpecialChars(searchValue)).map((result) => result.item));
    }
  }, [healthRecords, searchValue, locale]);

  const { pathname } = useLocation();
  useEffect(() => {
    localStorage.setItem(LAST_HOME_PAGE_KEY, pathname);
  }, [pathname]);

  return (
    <PageLayout title={t("patient")}>
      <Paper sx={{ overflow: "hidden", height: "100%", backgroundColor: "transparent" }} elevation={0}>
        {error ? (
          <Typography sx={{ my: 4 }} align="center" component="div">
            <Alert severity="error" data-testid="alert">
              {t("common.alerts.alert_notification")}
            </Alert>
          </Typography>
        ) : null}

        {healthRecords === undefined || filteredHealthRecords === undefined ? (
          <LinearProgress variant="query" />
        ) : healthRecords.length > 0 ? (
          <Box sx={{ height: "100%" }}>
            <Grid2 container spacing={{ xs: 1, md: 4 }} sx={{ alignItems: "center", pb: { xs: 0, sm: 1 } }}>
              <Grid2 size={{ xs: 12, sm: 8, md: 9 }}>
                <HealthRecordSearchBar setSearchValue={setSearchValue} searchValue={searchValue} />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 4, md: 3 }}>
                <Box sx={{ display: "flex", justifyContent: { xs: "flex-start", sm: "flex-end" } }}>
                  <Button
                    component={Link}
                    to={"new"}
                    startIcon={
                      <Avatar
                        sx={{
                          background: theme.palette.primary[500],
                          width: { xs: 24, md: 32 },
                          height: { xs: 24, md: 32 },
                        }}
                      >
                        <AddIcon fontSize="small" sx={{ color: "white" }} />
                      </Avatar>
                    }
                    sx={{
                      ".MuiButton-startIcon": {
                        minWidth: { xs: "40px", md: "56px" },
                        m: 0,
                      },
                      px: 1,
                      pb: 1,
                    }}
                    disableElevation
                  >
                    <Typography color="primary" fontWeight="500" sx={{ fontSize: { xs: 14, md: 16 } }}>
                      {t("patients.add")}
                    </Typography>
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
            {filteredHealthRecords.length > 0 ? (
              <>
                <Box sx={{ mt: 2, mb: 1 }}>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                    {t("common.resultsCount", {
                      smart_count: filteredHealthRecords?.length,
                      totalCount: healthRecords?.length,
                    })}
                  </Typography>
                </Box>
                <HealthRecordsList healthRecords={filteredHealthRecords} refreshCallback={fetchHealthRecords} />
              </>
            ) : (
              <NoHealthRecords />
            )}
          </Box>
        ) : (
          <NoHealthRecords />
        )}
      </Paper>
    </PageLayout>
  );
}
