import { Alert, Box, Slide, SlideProps, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchHealthRecord } from "../../hooks/useFetchHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { HealthRecordForm, HealthRecordFormHandler } from "./HealthRecordForm";
import { useApis } from "../../providers/Dependencies";
import { useAppContext } from "../../hooks/useAppContext";
import { useHealthRecord } from "../../../store";

export function EditHealthRecord() {
  const { t } = useI18n();

  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const { id } = useParams() as { id: string };
  const { refetch } = useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);

  const onSubmit: HealthRecordFormHandler = useCallback(
    (body, id) => {
      (async () => {
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.healthRecordApi.updateHealthRecord(organizationId, teamId, id as string, body);
          } else {
            await apis.pro.healthRecordApi.updateHealthRecord(id as string, body);
          }

          setErrorDisplayed(false);
          setSuccessDisplayed(true);
          refetch();
        } catch (_error) {
          setErrorDisplayed(true);
          setSuccessDisplayed(false);
        }
      })();
    },
    [apis, refetch, organizationId, teamId],
  );

  if (healthRecord === undefined) {
    return null;
  }

  return (
    <>
      <HealthRecordForm onSubmit={onSubmit} healthRecord={healthRecord} />
      <Box sx={{ maxWidth: "900px", mt: 2 }}>
        <CustomSnackbar
          open={errorDisplayed}
          onClose={() => setErrorDisplayed(false)}
          message={t("common.alerts.alert_classic")}
          severity="error"
        />
        <CustomSnackbar
          open={successDisplayed}
          onClose={() => setSuccessDisplayed(false)}
          message={t("health_records.success")}
          severity="success"
        />
      </Box>
    </>
  );
}

function TransitionLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  severity: "error" | "success";
  autoHideDuration?: number;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  open,
  onClose,
  message,
  severity,
  autoHideDuration = 3000,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={TransitionLeft}
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
      }}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
