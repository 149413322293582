import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Daphne } from "@syadem/daphne-js";
import { Asserts } from "yup";
import { calculateAge } from "../../utils/ageCalculator";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDaphne, useDiseasesByVaccine } from "../hooks";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import { getVaccinationsSchema } from "../pages/health_record/AddVaccinations";
import { IconMedicalInstrumentSyringe } from "./icons/IconMedicalInstrumentSyringe";
import { CertificationMethodEnum } from "@syadem/kairos-pro-js";
import { dayjsWithLocale } from "../../utils/dayjs";

export function AddVaccinationsList(props: any) {
  const _vaccinationsSchema = getVaccinationsSchema();
  const {
    vaccinationActs,
    birthDate,
    deleteVaccinationAct,
  }: {
    vaccinationActs: Asserts<typeof _vaccinationsSchema>[];
    birthDate: Date;
    deleteVaccinationAct: (index: number) => void;
  } = props;
  const { t, locale } = useI18n();
  const daphne = useDaphne();

  function certificationMethodText(certificationMethod: CertificationMethodEnum) {
    switch (certificationMethod) {
      case "not_validated":
        return t("vaccines.confirmation.not_validated");
      case "proof":
        return t("vaccines.confirmation.validate");
      case "execution":
        return t("vaccines.confirmation.do_validate");
      default:
        break;
    }
  }

  return (
    <List sx={{ width: "100%", pt: 0.5 }}>
      {daphne &&
        vaccinationActs.map((vaccinationAct, index) => (
          <ListItem
            key={`vaccinationAct${index}`}
            sx={{
              mb: 1,
              py: { xs: 1, md: 1 },
              border: `solid 1px ${theme.palette.neutral[200]}`,
              borderRadius: 1,
              bgcolor: "background.paper",
            }}
            secondaryAction={
              <IconButton size="small" onClick={() => deleteVaccinationAct(index)}>
                <DeleteOutlineIcon color="error" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  background: theme.palette.primary[100],
                  width: { xs: 40, sm: 48 },
                  height: { xs: 40, sm: 48 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.primary[500],
                    ".iconSyringe": {
                      height: "24px !important",
                    },
                  }}
                >
                  <IconMedicalInstrumentSyringe />
                </Box>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<Vaccine vaccineId={vaccinationAct.vaccineId} daphne={daphne} />}
              secondary={
                <>
                  <Typography component="span" variant="body2" sx={{ m: 0 }}>
                    {vaccinationAct.booster && <span>{`${t("vaccines.booster")} - `}</span>}
                    <span>
                      {t("vaccines.administration_country", {
                        date: dayjsWithLocale(vaccinationAct.performedOn, locale),
                        age: calculateAge(vaccinationAct?.performedOn, birthDate, false, t),
                        country: t(`countries.${vaccinationAct?.countryCode}`),
                      })}
                    </span>
                  </Typography>
                  <Typography component="span" sx={{ m: 0, display: "inherit" }} variant="body2">
                    {certificationMethodText(vaccinationAct?.certificationMethod as CertificationMethodEnum)}
                  </Typography>
                </>
              }
              sx={{ p: 1 }}
            />
          </ListItem>
        ))}
    </List>
  );
}

function Vaccine({ vaccineId, daphne }: { vaccineId: string; daphne: Daphne }) {
  const vaccine = daphne.repositories.vaccines.find(vaccineId);
  const { diseases } = useDiseasesByVaccine(vaccine);
  const { locale } = useI18n();

  return (
    <>
      {vaccine && (
        <Box display="flex" sx={{ alignItems: "center" }} component="span">
          <Typography component="span" fontWeight="500" color={theme.palette.neutral[600]}>
            {getTranslatedFieldByLocale(locale, vaccine.name)}{" "}
            {diseases && `(${diseases.map((disease) => getTranslatedFieldByLocale(locale, disease.name)).join(", ")})`}
          </Typography>
        </Box>
      )}
    </>
  );
}
