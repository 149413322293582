import { TranslationObject } from "./fr";

export const en: TranslationObject = {
  notifications: {
    unmanaged_error: "An unexpected error occurred",
  },
  users: {
    pro: "Medical professional",
    team: "Healthcare team",
  },
  logoTitle: "Digital vaccination record",
  area: "Professional area",
  injectionLocations: {
    left_deltoid: "Left deltoid",
    right_deltoid: "Right deltoid",
    left_thigh: "Left thigh",
    right_thigh: "Right thigh",
  },
  injectionMethods: {
    intramuscular: "Intramuscular",
    subcutaneous: "Subcutaneous",
    intradermal: "Intradermal",
    oral: "Oral",
    intranasal: "Intranasal",
  },
  sharingRequestStatus: {
    accepted: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
  },
  patient: "Patients",
  pro: "Medical professional",
  recommandations: {
    DiagnosticList: {
      infosMessage: "The vaccine decision support system follows the recommendations of the health authorities.",
    },
  },
  profile: {
    expandAll: "Expand all",
    shrinkAll: "Shrink all",
    closeQuestionnaireCta: "Close the health questionnaire",
    noConditions: "No characteristic",
    addConditionCta: "Add a characteristic",
    shortTitle: "Profile",
    conditionsTitle: "Characteristics filled",
    questionnaireTitle: "Complete the health profile here",
    deleteCondition: {
      title: "Delete the characteristic",
      content: "Are you sure you want to reject this characteristic?",
    },
    validate: "Validate profile",
    validated: "Profile validated",
    validatedBy: "Validated by %{certificator}",
    validatedByAPro: "Validated by a health professional",
  },
  notes: {
    title: "Notes",
    newNote: "New note",
    internalDescriptionPro: "Only visible to you",
    internalDescriptionTeam: "Visible to the %{teamName} team members",
    publicDescription: "Visible to anyone who can access this health record",
    internal: "Internal",
    public: "Shared",
    private: "Private",
    unknownAuthor: "Unknown author",
    deleteModalTitle: "Delete the note",
    deleteModalContent: "Are you sure you want to delete this note ?",
    editContent: "Edit content",
    cancelModifications: "Cancel modifications",
    lastModified: "Last updated %{distance} ago",
    addedAt: "Added %{distance} ago",
    addANote: "Add a note",
    newInternalNote: "New internal note",
    newPublicNote: "New shared note",
    newPrivateNote: "New private note",
    cancelForm: "Cancel",
  },
  organization: {
    address: "Address",
    noAddress: "Not provided",
    name: "Organization name",
    namePlaceholder: "ex: CHU de Bordeaux",
    creationGuidanceOne: "Fill the form with your organization informations.",
    creationGuidanceTwo: "Thereafter, you will be able to create teams within this new organization.",
    phoneNumber: "Phone number",
    noPhoneNumber: "Not provided",
    teamActivationPending:
      "The team %{name} creation will be validated by an administrator from MesVaccins.net as soon as possible.",
    settings: {
      title: "Settings",
      tabs: {
        subscription: "Subscription",
        teams: "Teams",
        info: "Info",
      },
    },
  },
  healthRecordsTransfer: {
    noTeamTitle: "No healthcare team",
    noTeamDescription: "You must belong to at least one healthcare team to transfer your health records.",
    guidanceOne: "Select the healthcare team you want to transfer your health records to.",
    guidanceTwo: "Health records will remain accessible through the individual Pro Mode.",
    guidanceThree: "Health records already shared with this team will automatically be ignored.",
    healthcareTeam: "Healthcare team",
    successMessage: "Health records successfully transfered",
    successMessageWithErrorsCount:
      "Health records transfer completed: %{errorsCount} health record(s) could not be transfered",
    inProgressGuidance: "Do not leave the page as long as the process is running.",
  },
  common: {
    paidfeature: "This feature is only available to professional accounts or teams with a subscription.",
    orgaPaidfeature: "The team must have a valid subscription to access this feature.",
    whySuscribe: "Why subscribe to the full version?",
    unlockPaidFeatures: "Limited mode, unlock all features with the subscription",
    helpCenter: "Help center",
    createHelpTicket: "Contact us",
    and: "and",
    others: "Others",
    loading: "Loading...",
    navbar: {
      title: "Vaccine Recommendations",
      subtitle: "For you and your loved ones",
    },
    copied: "Copied !",
    resultsCount:
      "%{smart_count} displayed result in %{totalCount} |||| %{smart_count} displayed results in %{totalCount}",
    printing: "Printing",
    cta: {
      previous: "Previous",
      back: "Back",
      next: "Next",
      save: "Save",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      print: "Print",
      close: "Close",
      reset: "Reset",
      submit: "Submit",
      savePdf: "PDF",
      validate: "Validate",
      logout: "Logout",
      accept: "Accept",
      reject: "Reject",
      remove: "Remove",
      search: "Search",
      view: "Consult",
      subscribe: "Subscribe",
      enter: "Enter",
    },
    errors: {
      title: "Error %{statusCode}",
      title_unknown: "Unknown error",
      404: "The requested page doesn't exist.",
      401: "You don't have access to the requested page.",
      default: "Something unexpected happened, our technical team has been notified.",
      noResults: "No results",
    },
    gender: {
      m: "Male",
      f: "Female",
      s: "Sexe",
    },
    conditions: {
      at: "at",
      equalTo: "is equal to",
    },
    dates: {
      datePlaceholder: "DD/MM/YYYY",
      dateFormat: "dd/MM/yyyy",
      "DD/MM/YYYY": "DD/MM/YYYY",
      "MM/DD/YYYY": "MM/DD/YYYY",
      placeholder: "DD/MM/YYYY",
      bornThe: "born the %{birthDate}",
      born: {
        male: "born",
        female: "born",
      },
      day: "day |||| days",
      month: "month |||| months",
      week: "week |||| weeks",
      year: "year |||| years",
      toDoIn: "To do in",
      toDoAsap: "To do asap",
      lastModified: "Last updated %{distance} ago",
      addedAt: "Added %{distance} ago",
      processedAt: "Processed %{distance} ago",
    },
    interface: {
      language: "language",
      patients: "Patients",
      my_practice: "Individual Pro Mode",
      teamAccounts: "Pro Team Mode |||| Pro Team Mode",
      unshared_health_records: "Archived records",
      pending_sharing_requests: "Pending requests",
      informations: "My account",
      sort_by: "Sort by",
      back_to_home: "Back to home",
      reconnect: "Reconnect",
      organizations: "organizations",
      premium: "Premium",
      basic: "Basic",
      health_records_transfer: "Health records transfer",
      newTeam: "New team",
      pendingTeamActivation: "Activation pending",
      newOrganization: "New organization",
      subscription: "Subscription",
      subscriptionRequired: "Subscription required",
      paymentMethodRequired: "Payment method required",
      organizationSettings: "Paramètres",
    },
    user_infos: {
      title: "We invite you to check the accuracy of the information provided",
      first_names: "First name(s)",
      first_name: "First name",
      last_name: "Last name",
      usual_name: "Usual name",
      customary_name: "Customary name",
      birth_date: "Birth date",
      social_security_number: "Social security number",
      social_security_number_infos:
        "Please fill in the social security number to pre-populate the gender, month and year of birth",
      phone: "Phone number",
      address: "Address",
      zip_code: "Postal code of place of residence",
      city: "City",
      country: "Country",
      placeholder: "Search by name, birth date, and/or NIR",
      team_placeholder: "Search by name",
      birth_country: "Country of birth",
      birth_place: "Place of birth",
      birth_zip_code: "Postal code of birth",
      subscription: {
        paid_by_pro: "Premium subscription paid by professional : %{name}",
        paid_by_organization: "Premium subscription paid by organization : %{name}",
        valid_until: "End of subscription : %{date}",
      },
    },
    alerts: {
      alert_notification: "An error occurred, our technical team has been notified.",
      alert_classic: "An error occurred.",
      alert_profile: "Your profile has been updated.",
      alert_auth: "An error occurred during the authentication process, our technical team has been notified.",
      alert_auth_unknown_error: "Authentication error",
      alert_logout: "You have been disconnected",
      alert_logout_long: "Your session has expired, please reconnect.",
      alert_saved_success: "Update successfull",
      alert_auth_failed: "Authentication Failed",
    },
    add_team: "Create team",
    city: {
      placeholder: "ex: Bordeaux or 33000",
    },
    retry: "Retry",
  },
  payment: {
    title: "Subscription required",
    description_1: "To take full advantage of all MesVaccins.net features, a subscription is required..",
    description_2: "This subscription give you access to :",
    description_3: "- vaccine decision support",
    description_4:
      "- to the rapid vaccine diagnostic tool (Mentor Pro), which does not require the creation of an health record.",
    description_5: "- managing digital vaccination records as part of a team",
    description_6: "The annual subscription fee is €299 incl. VAT",
    description_7: "Contact us via the help center in the following cases:",
    description_8: "- You work in the Nouvelle-Aquitaine region",
    description_9: "- You are a pharmacist in Auvergne-Rhône-Alpes",
    error: "A payment error has occurred. If the error persists, please contact support.",
    freeTrial: "Create your organization to benefit from 30 days of trial without engagement",
    howToSubscribe: "Administrators of the organization can manage the subscription using dedicated menu item",
    diagnosticDetails:
      "Status according to the vaccines received, health profile and current recommendations, justification of the recommandation, eligible vaccines, professionals allowed to perform the vaccinations and sources",
  },
  payment_success: {
    title: "Thank you!",
    description_1: "Thank you for registering, you can now use all features.",
  },
  payment_error: {
    title: "An error occured",
    description_1: "The payment failed, please try again.",
  },
  paywall: {
    title: "Premium subscription required",
    description_1: "A premium subscription is required to access this feature.",
    cta: "Subscribe",
  },
  informations: {
    title: "Complete your profile",
  },
  subscriptions: {
    title: "Subscription management",
    seats: "seats",
    amount: "%{amount}€/year inc. tax",
    subscriptionStartedOn: "Subscription started on %{date}",
    subscriptionTrialEndsOn: "Trialing until %{date}",
    subscriptionNextRenewal: "Next renewal on %{date}",
    subscriptionEndsOn: "Subscription will end on %{date}",
    paymentInformations: "Payment informations",
    cardDetails: "Card %{brand} ending with %{lastFourDigits} (exp. %{expiration})",
    noPaymentMethod: "No payment method provided",
    updatePaymentMethod: "Update payment method",
    addPaymentMethod: "Add a payment method",
    cancel: "Cancel subscription",
    aboutCancellation1: "Your subscription will stop at the end of the current billing period.",
    aboutCancellation2: "Thus, you will be able to use your services until %{date}.",
    aboutCancellation3: "Note that you can reactivate your subscription at any time.",
    stopCancellation: "Reactivate subscription",
    successfullyCanceled: "Subscription cancellation request successfully handled",
    successfullyReactivated: "Subscription successfully reactivated",
    quantitySuccessfullyUpdated: "Amount of seats successfully adjusted",
    forbiddenDowngrade: "You cannot adjust the amount of seats to a value below the amount of seats currently occupied",
    amountOfSeats: "Amount of seats",
    perFiveSeats: "x 5 seats",
    totalCalculatedSeats: "%{seats} seats",
    totalCalculatedAmount: "%{amount}€/year inc. tax",
    seatsOccupiedAmount: "There is currently %{amount} occupied seat(s) within your organization.",
    adjustSeats: "Adjust the value at your convenience to match the size of your staff.",
    adjustSeatsQuantity: "Adjust amount of seats",
    paymentFailed: "Provided payment method could not be charged",
    upgradeSeats1:
      "To benefit from your additonal seats, you have to pay for the corresponding annual amount prorated accoridng to the current billing period.",
    upgradeSeats2: "Thus, the amount %{amount}€ inc. tax will be charged on your account.",
    upgradeSeats3: "The new amount of your annual subscription will follow the usual billing schedule.",
    downgradeSeats1: "The removal of the seats takes effect immediately.",
    downgradeSeats2:
      "The amount already charged for these seats for the current billing period will be deducted from your next invoice.",
    resume: "Reactivate subscription",
    aboutUnpaidResuming1: "In order to resume your subscription, you have to pay the latest unpaid invoice.",
    aboutUnpaidResuming2: "Make sure beforehand to provide a valid payment method.",
    aboutUnpaidResuming3: "Upcoming automatic payments will be charged according to the usual billing schedule.",
    aboutPausedResuming1: "In order to resume your subscription, you have to pay the amount for the upcoming year.",
    aboutPausedResuming2: "Make sure beforehand to provide a valid payment method.",
    aboutPausedResuming3: "Upcoming automatic payments will be charged each year at the same schedule.",
    invoices: "Invoices",
    noInvoice: "No invoice",
    referToOrganizationAdmin:
      "Reach out for an administrator of your organization or contact MesVaccins.net support to manage your subscription",
    referToSupport: "Contact MesVaccins.net support to manage your subscription",
    specialDiscount: "Special discount",
    invalidPromotionCode: "Invalid promotion code",
    applyPromotionCode: "Apply promotional code",
    discountSuccessfullyApplied: "Discount successfully applied",
    appliedDiscount: "%{amount}€ discount applied (%{name})",
    amountWithDiscount: "%{amount}€/year inc. tax instead of",
    statuses: {
      trialing: "Trial",
      active: "Active",
      paused: "Inactive",
      past_due: "Unpaid",
      canceled: "Canceled",
      unpaid: "Unpaid",
    },
    invoiceStatuses: {
      open: "Unpaid",
      paid: "Paid",
      uncollectible: "Canceled",
      void: "Canceled",
    },
  },
  sharing_requests: {
    status: "No pending sharing requests",
    notification: "You will be notified in case a patient's health record is shared with you.",
    error: "An error occurred.",
    success: "Request processed successfully.",
    reject: "Reject sharing request",
    reject_validation: "Are you sure you want to reject this sharing request ?",
    back_to_health_records: "Back to my patients",
  },
  patients: {
    add: "Add a patient",
    name: "Name",
    none: "No patient",
    nothing: "No infos",
    title: "Patients",
    tabs: {
      immunization: "Immunization",
      vaccinations_to_be_done: "Vaccines to be done",
      profile: "Health profile",
      identity: "Identity",
      share: "Share",
    },
  },
  vaccines: {
    count: "%{smart_count} vaccination |||| %{smart_count} vaccinations",
    placeholder: "ex : Flu vaccine",
    distributionDate: "Available since %{date}",
    title: "Vaccinations",
    error_messages: {
      error_1: "The vaccination act cannot be performed in the future or before the birth date.",
      error_2: "The vaccination act cannot be performed after the vaccine expiration date.",
      error_3: "The injection method is incorrect",
      error_4: "The injection location is incorrect",
    },
    validate: "Validate vaccination",
    validated: "Validated act",
    not_validated: "Unvalidated act",
    aProfessional: "a health professional",
    aTeam: "a healthcare team",
    aTeamMember: "a professional from the team %{teamInfo}",
    validatedUponExecutionBy: "Validated on %{date} upon execution by %{certificator}",
    validatedUponProofBy: "Validated upon proof on %{date} by %{certificator}",
    editableByProOnly: "Only the health professional who performed this act can edit it",
    editableByTeamOnly: "Only members of the healthcare team which performed this act can edit it",
    confirmation: {
      do_validate: "I realized this act",
      not_validated: "Act not validated",
      validate: "I validate this act upon presentation of a certificate or vaccination record",
    },
    no_vaccinations: "No vaccination registered",
    no_vaccinations_sub: "No vaccination present on this health record on the date of cessation of sharing",
    administration_date: "Administration date",
    administered_the: "Administered the ",
    administration: "Administered the %{date} at %{age} old.",
    administration_booster: "Booster administered the %{date} at %{age} old.",
    administration_country: "Administered the %{date} at %{age} old in %{country}.",
    administered_at: " at ",
    name: "Vaccine's name",
    name_or_disease: "Vaccine's or disease's name",
    add_vaccination: "Add vaccination",
    add_vaccination_msg: "Add your vaccinations to track your vaccination status.",
    view_vaccination: "Consult a vaccination",
    edit_vaccination: "Edit vaccination",
    delete_vaccination: "Delete vaccination",
    delete_vaccination_validation: "Are you sure you want to delete this vaccination ?",
    date_act: "Date of vaccination",
    act_date_greater_than_expiration_date: "A la date de l’acte, la date d’expiration est atteinte",
    expiration_date: "Expiration date",
    more: "Show more Vaccines",
    serial_number: "Serial number",
    batch_number: "Batch number",
    booster: "Booster",
    injection_location: "Injection location",
    injection_path: "Method of administration",
    additional_information: "Detailed datas",
    printCertified: "Only validated vaccines can be printed",
    addVaccinationConfirmed: "Vaccination added with success",
    addVaccinations: {
      title: "Vaccines to save",
      no_vaccines_added: "No vaccines added, please use the form",
    },
    addVaccinationType: {
      simple_entry: "Simple entry",
      simple_entry_subtitle: "Entry of a single vaccine with all its details",
      multiple_entry: "Multiple entry",
      multiple_entry_subtitle: "Entry of several vaccines without details",
    },
    addSimilarVaccination: {
      title: "Similar vaccination",
      details:
        "A vaccination with a similar vaccine, on the same date, already exists for this patient. Are you sure you want to add it?",
    },
    vaccinesDistributed: "Vaccines available on the date indicated",
    otherVaccines: "Other vaccines (available abroad, generic or older)",
    distributedSince: "Available since %{distributionStart}",
    distributedBetween: "Available from %{distributionStart} to %{distributionEnd}",
    inlineForm: {
      addHistoricVaccination: "Quick entry of historical vaccination",
      addNewVaccination: "Add new vaccination",
    },
    displayByDiseases: "Disease",
    vaccineOfTheDay: "Vaccine of the day",
  },
  health_records: {
    success: "Health record successfully updated.",
    birth_date_placeholder: "DD/MM/YYYY",
    none_unshared: "No archived health record.",
    none_shared: "No share",
    share_button: "Share health record",
    share_button_2: "Share health record",
    share_pros: "Share your health record with other medical professionals",
    share_patients_and_pros: "Share your health record with the patient or other medical professionals",
    share_pro: "Share with a medical professional",
    sharing_request_sent: "Sharing request sent.",
    patient_is_owner: "The patient is owner of his record",
    patient_is_not_owner: "The patient is not the owner of his record",
    cancel_invite: "Cancel invite",
    cancel_invite_validation: "Are you sure you want to cancel this invite ?",
    search_other_pro: "New Share",
    share: "Share",
    copy_share_link: "Copy share link",
    copy_share_link_msg: "By clicking below, you will copy into your print-sharing link..",
    copy_share_link_success: "Link copied to clipboard.",
    pro_attestation: "I certify that I am authorised by the patient to share this booklet",
    history: "Find here the history of the all the health records you've had access to",
    create_or_access: "Create new patients or access existing health records via the share functionality",
    remove_from_patients: "Remove health record from my patients",
    remove_from_patients_validation: "Are you sure you want to remove this health record from your patients ?",
    remove_from_patients_validation_intro:
      "The removed notebook will be inserted into the « Archived records » section where it will be searchable but not editable.",
    delete_the_health_record: "Delete the health record",
    delete_the_health_record_validation: "Are you sure you want to delete this health record ?",
    delete_the_health_record_validation_intro:
      "The deleted notebook will be inserted into the « Archived records » section where it will be searchable but not editable.",
    shared: "Record shared with patient",
    shared_pro: "Record shared with another professional or care team",
    non_shared: "Record not shared with patient",
  },
  share: {
    browse_professional_directory:
      "Browse the directory to select the health professional or the healthcare team you want to grant access to your health record.",
    with_a_health_professional: "With a health professional",
    with_its_owner: "With the holder",
    share_the_health_record: "Share this health record",
    grant_ownership_disclaimer_1:
      "You are about to offer the possibility to a patient to become owner of the health record.",
    link_copied: "Link copied",
    generate_sharing_link: "Generate link",
    link_copied_to_clipboard: "Link has been copied to clipboard",
    copy_sharing_link: "Copy link",
    copy_sharing_link_disclaimer_1: "By clicking below, you will copy an ownership transfer link to your clipboard.",
    copy_sharing_link_disclaimer_2: "Once the link has been copied, you may send it through email, SMS etc.",
    copy_sharing_link_disclaimer_3:
      "For security reasons, the person will have to provide the date of birth of the health record to access it.",
    share_with_team_disclaimer:
      "A health record shared with a healthcare team will be accessible by all of its members.",
    email_invalid: "Invalid email",
    email_sent: "Email successfully sent to <<%{email}>>",
    email_sharing: "Share by sending an email:",
    email_sharing_description:
      "Tell the patient that the link is only valid for 30 days. If they do not receive it immediately, they should check their SPAMS.",
    email_grant_ownership: "Give ownership by sending an email:",
    share_link: "Copy this link and share it as you want",
    share_link_share_description: "This link can be shared by SMS or e-mail.",
    grant_ownership_link: "Copy this link and share it as you want",
    grant_ownership_link_description: "This link can be shared by SMS or e-mail.",
    qrcode_share: "Scan qrcode to share",
    qrcode_share_description: "Quick method : to be preferred if the patient has a smartphone.",
    qrcode_grant_ownership: "Scan qrcode to become owner",
    or: "OR",
    sms_grant_ownership: "Give ownership by sending a sms :",
    sms_share: "Give access by sending a sms :",
    other_share_methods: "Other sharing methods (QrCode, link)",
    other_transfer_methods: "Other transfer methods (QrCode, link)",
    generate_pdf: "Print QrCode",
  },
  pdfView: {
    shareHealthRecord: "This notebook is shared with you by %{user}",
    qrCode: "This QRCode is valid until %{date}",
    vaccinesList: "List of the patient's vaccines to date (%{date})",
  },
  account: {
    email: "Email",
    password: "Password",
    mobile: "Mobile phone number",
    infos:
      "Your email address and password are your login details. The mobile phone number allows a 2-factor authentication, guaranteeing the security of your account. No commercial use will be made of your personal data",
  },
  account_validation: {
    title: "Account verification",
    rpps_verification: "Verification of your RPPS number",
    cps_button: "Authenticate with my CPS card",
    info: "For security reasons, please authenticate with your CPS or e-CPS card",
    manual_validation_option: "I can't authenticate with my CPS card",
    manual_validation_option_prompt:
      "Please send your proof of profession (such as a scan of your CPS card) to the following email address :",
  },
  datamatrix: {
    dialogTitle: "Scan a datamatrix code",
    preventionMethod: "Vaccine : ",
    serialNumber: "Serial number : ",
    batchNumber: "Lot number : ",
    expirationDate: "Expiration date : ",
    scanCta: "Scan Datamatrix code",
    loading: "Query servers",
    noDeviceError: "No camera detected on your device.",
    notFoundToast: "No vaccine match found on our servers, please manually specify the vaccine name.",
    scanError: "There's been an error scanning the Datamatrix code, please try again.",
    serverError: "There's been an error trying to reach the vaccine database, please try again.",
    successToast: "The form has successfully been filled with the scan results.",
    camera: {
      tabTitle: "Camera",
      steps: {
        "1": "Put the datamatrix code in the yellow square",
        "2": "The information search is automatic",
        "3": "The popup will automatically close and pre-fill the form",
      },
    },
    barCodeScanner: {
      tabTitle: "Bar Code Scanner",
      steps: {
        "1": "Scan the code with the barcode scanner",
        "2": "The code will appear in the text field",
        "3": "By clicking on the barcode reader the popup will close automatically and pre-fill the form",
      },
    },
    inverseImage: "Inverse camera image",
  },
  healthProfessional: {
    rppsNumber: "RPPS Number",
    noRppsNumber: "No RPPS number",
  },
  team: {
    identificationNumber: "Identification number",
    name: "Team name",
    namePlaceholder: "ex: CHU de Bordeaux - Radiologie",
    address: "Adress",
    city: "City",
    zipCode: "Zip code",
    phoneNumber: "Contact phone number",
    invite: "Invite a new member",
    no_members: "No team member",
    member_list: "Team members",
    member_list_remove: "Remove a team member",
    member_list_remove_description: "The member will no longer be able to access health records shared with the team.",
    role: {
      admin: "Administrator",
      member: "Member",
    },
    sendInvitation: {
      cta: "Send invitation",
      success: "Invitation e-mail has been sent",
      enterEmail: "Enter the e-mail address of the healthcare professional you wish to invite to your team",
      description:
        "An e-mail will be sent to this recipient. It contains a one-time invitation link, valid for 7 days.",
      noSeatsLeft:
        "There is no available seat in your organisation (the quantity can be adjusted in the 'Subscription' menu reserved to administrators)",
      seatsAmountLeft: "Your organization has %{amount} available seat(s)",
    },
    createPending: {
      success:
        "Your request to add a team has been processed. A MyVaccines.net administrator will process your request as soon as possible.",
      new: "Create a team",
      description:
        "The name of your team will be visible to the patient or another professional in the directory integrated into the digital vaccination record.",
    },
    acceptInvitation: {
      title: "Join the team",
      success: "You join the team %{teamName}.",
      error: "An error occured, please contact the team administrator",
    },
    pendingTeamActivation: "Team awaiting activation",
    none: "No activated team",
    add: "Create a team",
    addTeamsForOrganization: "Add new teams to your organization",
    contactAdminToAddTeams:
      "Contact your organisation administrator or MesVaccins.net support to be able to add new teams",
    reactivateSubscriptionToAddTeam: "Reactivate your subscription to be able to add new teams",
    addPaymentMethodToAddTeam: "Provide a valid payment method to be able to add new teams",
    management: "Teams management",
    members: "member |||| members",
  },
  mentor: {
    title: "Mentor PRO : Vaccine decision support",
  },
  verification: {
    cta: "Request approval of the account",
    alertMessage: "Your email address is now validated",
    text1:
      "Your registration must now be approved by the MyVaccines.net team. To do this, we need to verify your profession",
    text2:
      "To do so, please send us at least one of the following documents as an attachment: copy of the CPS card, diploma or registration with the Order, by clicking on the button below. ",
  },
  accountSettings: {
    visibilitySettings: {
      title: "Health professionals directory",
      tooltip: "Presence in the directory is required to receive health records sharings",
      display: "Display",
      hide: "Hide",
    },
  },
  yup: {
    mixed: {
      default: "is invalid",
      required: "is a required field",
      oneOf: "must be one of the following values: %{values}",
      notOneOf: "must not be one of the following values: %{values}",
      defined: "is a required field",
    },
    string: {
      length: "must be exactly %{length} characters",
      min: "must be at least %{min} characters",
      max: "must be at most %{max} characters",
      matches: "is invalid and must respect the required format",
      email: "must be a valid email",
      url: "must be a valid URL",
      uuid: "must be a valid UUID",
      trim: "must be a trimmed string",
      lowercase: "must be a lowercase string",
      uppercase: "must be a upper case string",
    },
    number: {
      min: "must be greater than or equal to %{min}",
      max: "must be less than or equal to %{max}",
      lessThan: "must be less than %{less}",
      moreThan: "must be greater than %{more}",
      positive: "must be a positive number",
      negative: "must be a negative number",
      integer: "must be an integer",
    },
    date: {
      min: "field must be the %{min} or later",
      max: "field must be the %{max} or earlier",
      invalid: "is not a valid date",
    },
    boolean: {
      isValue: "field must be %{value}",
    },
    object: {
      noUnknown: "field has unspecified keys: %{unknown}",
    },
    array: {
      min: "field must have at least %{min} items",
      max: "field must have less than or equal to %{max} items",
      length: "must be have %{length} items",
    },
  },
  countries: {
    AFG: "Afghanistan",
    ALB: "Albania",
    DZA: "Algeria",
    ASM: "American Samoa",
    AND: "Andorra",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctica",
    ATG: "Antigua and Barbuda",
    ARG: "Argentina",
    ARM: "Armenia",
    ABW: "Aruba",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",
    BHS: "Bahamas (the)",
    BHR: "Bahrain",
    BGD: "Bangladesh",
    BRB: "Barbados",
    BLR: "Belarus",
    BEL: "Belgium",
    BLZ: "Belize",
    BEN: "Benin",
    BMU: "Bermuda",
    BTN: "Bhutan",
    BOL: "Bolivia (Plurinational State of)",
    BES: "Bonaire, Sint Eustatius and Saba",
    BIH: "Bosnia and Herzegovina",
    BWA: "Botswana",
    BVT: "Bouvet Island",
    BRA: "Brazil",
    IOT: "British Indian Ocean Territory (the)",
    BRN: "Brunei Darussalam",
    BGR: "Bulgaria",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cabo Verde",
    KHM: "Cambodia",
    CMR: "Cameroon",
    CAN: "Canada",
    CYM: "Cayman Islands (the)",
    CAF: "Central African Republic (the)",
    TCD: "Chad",
    CHL: "Chile",
    CHN: "China",
    CXR: "Christmas Island",
    CCK: "Cocos (Keeling) Islands (the)",
    COL: "Colombia",
    COM: "Comoros (the)",
    COD: "Congo (the Democratic Republic of the)",
    COG: "Congo (the)",
    COK: "Cook Islands (the)",
    CRI: "Costa Rica",
    HRV: "Croatia",
    CUB: "Cuba",
    CUW: "Curaçao",
    CYP: "Cyprus",
    CZE: "Czechia",
    CIV: "Côte d'Ivoire",
    DNK: "Denmark",
    DJI: "Djibouti",
    DMA: "Dominica",
    DOM: "Dominican Republic (the)",
    ECU: "Ecuador",
    EGY: "Egypt",
    SLV: "El Salvador",
    GNQ: "Equatorial Guinea",
    ERI: "Eritrea",
    EST: "Estonia",
    SWZ: "Eswatini",
    ETH: "Ethiopia",
    FLK: "Falkland Islands (the) [Malvinas]",
    FRO: "Faroe Islands (the)",
    FJI: "Fiji",
    FIN: "Finland",
    FRA: "France",
    GUF: "French Guiana",
    PYF: "French Polynesia",
    ATF: "French Southern Territories (the)",
    GAB: "Gabon",
    GMB: "Gambia (the)",
    GEO: "Georgia",
    DEU: "Germany",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Greece",
    GRL: "Greenland",
    GRD: "Grenada",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernsey",
    GIN: "Guinea",
    GNB: "Guinea-Bissau",
    GUY: "Guyana",
    HTI: "Haiti",
    HMD: "Heard Island and McDonald Islands",
    VAT: "Holy See (the)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hungary",
    ISL: "Iceland",
    IND: "India",
    IDN: "Indonesia",
    IRN: "Iran (Islamic Republic of)",
    IRQ: "Iraq",
    IRL: "Ireland",
    IMN: "Isle of Man",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JPN: "Japan",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Korea (the Democratic People's Republic of)",
    KOR: "Korea (the Republic of)",
    KWT: "Kuwait",
    KGZ: "Kyrgyzstan",
    LAO: "Lao People's Democratic Republic (the)",
    LVA: "Latvia",
    LBN: "Lebanon",
    LSO: "Lesotho",
    LBR: "Liberia",
    LBY: "Libya",
    LIE: "Liechtenstein",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    MAC: "Macao",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaysia",
    MDV: "Maldives",
    MLI: "Mali",
    MLT: "Malta",
    MHL: "Marshall Islands (the)",
    MTQ: "Martinique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MYT: "Mayotte",
    MEX: "Mexico",
    FSM: "Micronesia (Federated States of)",
    MDA: "Moldova (the Republic of)",
    MCO: "Monaco",
    MNG: "Mongolia",
    MNE: "Montenegro",
    MSR: "Montserrat",
    MAR: "Morocco",
    MOZ: "Mozambique",
    MMR: "Myanmar",
    NAM: "Namibia",
    NRU: "Nauru",
    NPL: "Nepal",
    NLD: "Netherlands (the)",
    NCL: "New Caledonia",
    NZL: "New Zealand",
    NIC: "Nicaragua",
    NER: "Niger (the)",
    NGA: "Nigeria",
    NIU: "Niue",
    NFK: "Norfolk Island",
    MNP: "Northern Mariana Islands (the)",
    NOR: "Norway",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palau",
    PSE: "Palestine, State of",
    PAN: "Panama",
    PNG: "Papua New Guinea",
    PRY: "Paraguay",
    PER: "Peru",
    PHL: "Philippines (the)",
    PCN: "Pitcairn",
    POL: "Poland",
    PRT: "Portugal",
    PRI: "Puerto Rico",
    QAT: "Qatar",
    MKD: "Republic of North Macedonia",
    ROU: "Romania",
    RUS: "Russian Federation (the)",
    RWA: "Rwanda",
    REU: "Réunion",
    BLM: "Saint Barthélemy",
    SHN: "Saint Helena, Ascension and Tristan da Cunha",
    KNA: "Saint Kitts and Nevis",
    LCA: "Saint Lucia",
    MAF: "Saint Martin (French part)",
    SPM: "Saint Pierre and Miquelon",
    VCT: "Saint Vincent and the Grenadines",
    WSM: "Samoa",
    SMR: "San Marino",
    STP: "Sao Tome and Principe",
    SAU: "Saudi Arabia",
    SEN: "Senegal",
    SRB: "Serbia",
    SYC: "Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapore",
    SXM: "Sint Maarten (Dutch part)",
    SVK: "Slovakia",
    SVN: "Slovenia",
    SLB: "Solomon Islands",
    SOM: "Somalia",
    ZAF: "South Africa",
    SGS: "South Georgia and the South Sandwich Islands",
    SSD: "South Sudan",
    ESP: "Spain",
    LKA: "Sri Lanka",
    SDN: "Sudan (the)",
    SUR: "Suriname",
    SJM: "Svalbard and Jan Mayen",
    SWE: "Sweden",
    CHE: "Switzerland",
    SYR: "Syrian Arab Republic",
    TWN: "Taiwan (Province of China)",
    TJK: "Tajikistan",
    TZA: "Tanzania, United Republic of",
    THA: "Thailand",
    TLS: "Timor-Leste",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TKM: "Turkmenistan",
    TCA: "Turks and Caicos Islands (the)",
    TUV: "Tuvalu",
    UGA: "Uganda",
    UKR: "Ukraine",
    ARE: "United Arab Emirates (the)",
    GBR: "United Kingdom of Great Britain and Northern Ireland (the)",
    UMI: "United States Minor Outlying Islands (the)",
    USA: "United States of America (the)",
    URY: "Uruguay",
    UZB: "Uzbekistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (Bolivarian Republic of)",
    VNM: "Vietnam",
    VGB: "Virgin Islands (British)",
    VIR: "Virgin Islands (U.S.)",
    WLF: "Wallis and Futuna",
    ESH: "Western Sahara",
    YEM: "Yemen",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
    ALA: "Åland Islands",
  },
};
