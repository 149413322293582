import { HealthRecordApi } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class ProHealthRecordQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call(id: string) {
    const healthRecord = (await this.healthRecordApi.getHealthRecord(id)).healthRecord;

    this.store.setState((state) => {
      const proHealthRecordIds = state.proHealthRecordIds ?? [healthRecord.id];

      return {
        healthRecords: { ...state.healthRecords, [healthRecord.id]: healthRecord },
        proHealthRecordIds: proHealthRecordIds.includes(healthRecord.id)
          ? state.proHealthRecordIds
          : [healthRecord.id, ...proHealthRecordIds],
      };
    });
  }
}
