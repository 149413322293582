import { Alert, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PageLayout } from "../../layout/PageLayout";
import { HealthRecordForm, HealthRecordFormHandler } from "./HealthRecordForm";
import { useI18n } from "../../hooks/useI18n";
import { useAppContext } from "../../hooks/useAppContext";
import { useServiceBus } from "../../hooks/useServiceBus";

export function NewHealthRecord() {
  const { t } = useI18n();
  const serviceBus = useServiceBus();
  const { organizationId, teamId } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);

  const onSubmit: HealthRecordFormHandler = useCallback(
    (body) => {
      (async () => {
        try {
          const service = await serviceBus.dispatch({
            type: "createHealthRecord",
            ...body,
            organizationId,
            teamId,
          });

          if (service.type == "success") {
            // TODO: Use service bus navigation
            const path = location.pathname.replace("/new", `/${service.value.id}`);
            navigate(path);
          } else {
            setErrorDisplayed(true);
          }
        } catch (_error) {
          setErrorDisplayed(true);
        }
      })();
    },
    [serviceBus, navigate, location, organizationId, teamId],
  );

  return (
    <PageLayout title={t("patients.add")}>
      <HealthRecordForm onSubmit={onSubmit} />
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
      </Snackbar>
    </PageLayout>
  );
}
