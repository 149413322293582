import { VaccinationAct } from "@syadem/kairos-pro-js";
import {
  DiagnosticForPatientBody,
  DiagnosticForPatientBodyPatientConditionsInner,
  DiagnosticForPatientBodyPatientPreventionActsInner,
} from "@syadem/sad-js";
import { iso8601Date } from "../utils/date";
import { DomainHealthRecord } from "./HealthRecord";

export function buildPatientFromHealthRecord(healthRecord: DomainHealthRecord): DiagnosticForPatientBody {
  return {
    patient: {
      birthdate: iso8601Date(healthRecord.birthDate),
      gender: healthRecord.gender.toLocaleLowerCase(),
      area_of_residency: {
        zipcode: healthRecord.zipCode == null ? undefined : healthRecord.zipCode,
      },
      prevention_acts: healthRecord.vaccinationActs.reduce(
        (acc: DiagnosticForPatientBodyPatientPreventionActsInner[], current: VaccinationAct) => [
          ...acc,
          {
            date: iso8601Date(current.performedOn),
            prevention_method_id: current.vaccineId,
            booster: Boolean(current.booster),
          },
        ],
        [],
      ),
      conditions: Object.entries(healthRecord.profile).reduce(
        (acc: DiagnosticForPatientBodyPatientConditionsInner[], current) => {
          const [id, condition] = current;
          if (condition.value === null || condition.value === false) {
            return acc;
          }
          return [
            ...acc,
            {
              id,
              value: condition.value,
            },
          ];
        },
        [],
      ),
    },
    requested_by_professional: true,
  };
}
