import { Diagnostic } from "@syadem/sad-js";
import { useEffect, useState } from "react";
import { DomainHealthRecord } from "../../domain/HealthRecord";
import { buildPatientFromHealthRecord } from "../../domain/patient";
import { useSad } from "./useSadApi";

const cache = new Map<string, Diagnostic>();

export function useDiagnostic(healthRecord: DomainHealthRecord | undefined): {
  isLoading: boolean;
  diagnostic?: Diagnostic;
  error?: Response | Error;
} {
  const api = useSad();
  const [diagnostic, setDiagnostic] = useState<Diagnostic | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const cacheKey = healthRecord ? JSON.stringify(healthRecord) : undefined;

  useEffect(() => {
    (async () => {
      if (api && healthRecord && cacheKey) {
        if (cache.has(cacheKey)) {
          setDiagnostic(cache.get(cacheKey));
          setIsLoading(false);
        } else {
          try {
            const diagnostic = await api.diagnosticForPatient(buildPatientFromHealthRecord(healthRecord));
            cache.set(cacheKey, diagnostic);
            setDiagnostic(diagnostic);
          } catch (error) {
            if (error instanceof Response || error instanceof Error) {
              setError(error);
            } else {
              throw error;
            }
          } finally {
            setIsLoading(false);
          }
        }
      }
    })();
  }, [api, healthRecord, cacheKey]);

  return { isLoading, diagnostic, error };
}
