import { ApiClient } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { SadvApi } from "@syadem/sad-js";
import { createContext, JSX, useContext } from "react";
import { Config, CountryConfig } from "../../appConfig";
import { Apis } from "../../network/apis";
import { Queries } from "../../queries";
import { ServiceBus } from "../../services/serviceBus";
import { AppStore } from "../../store";

export const DependenciesContext = createContext<Dependencies>(null!);

export interface Dependencies {
  store: AppStore;
  arianeApi: ApiClient;
  queries: Queries;
  serviceBus: ServiceBus;
  apis: Apis;
  sadApi: SadvApi;
  daphne: Daphne;
  config: Config;
  countryConfig: CountryConfig;
}

export function DependenciesProvider({
  children,
  dependencies,
}: {
  children: React.ReactNode;
  dependencies: Dependencies;
}): JSX.Element {
  return <DependenciesContext.Provider value={dependencies}>{children}</DependenciesContext.Provider>;
}

export function useQueries(): Queries {
  return useContext(DependenciesContext).queries;
}

export function useApis(): Apis {
  return useContext(DependenciesContext).apis;
}
