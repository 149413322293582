import { HealthRecordApi } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

export class TeamHealthRecordsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call(organizationId: string, teamId: string, searchValue?: string) {
    const { healthRecords, count } = await this.healthRecordApi.allHealthRecords(organizationId, teamId, searchValue);

    this.store.setState((state) => ({
      teamsHealthRecordIds: { ...state.teamsHealthRecordIds, [teamId]: { ids: healthRecords.map((h) => h.id), count } },
      healthRecords: healthRecords.reduce((acc, healthRecord) => {
        acc[healthRecord.id] = healthRecord;
        return acc;
      }, state.healthRecords ?? {}),
    }));
  }
}
