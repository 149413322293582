import { useCallback, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { theme } from "../../../layout/Theme";
import { ResponseError } from "@syadem/kairos-subscription-js";
import { LoadingButton } from "@mui/lab";
import DiscountIcon from '@mui/icons-material/Discount';
import { useServiceBus } from "../../../hooks/useServiceBus";
import { SubscriptionComponentProps } from "../settings/OrganizationSubscription";

export function PromotionCode(props: SubscriptionComponentProps) {
  const { subscriptionOrganization, setErrorDisplayed, setSuccessDisplayed, setErrorMessage, setSuccessMessage } = props;
  const { t } = useI18n();
  const serviceBus = useServiceBus();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [promotionCode, setPromotionCode] = useState<string>("");

  const handleApplySubscriptionDiscount = useCallback(
    async () => {
      if (promotionCode.length == 0) { return }
      setErrorDisplayed(false);
      setSuccessDisplayed(false);
      setIsSubmitting(true);

      try {
        await serviceBus.dispatch({
          type: "applySubscriptionDiscount",
          organizationId: subscriptionOrganization.id,
          subscription: { promotionCode }
        })

        setSuccessMessage(t("subscriptions.discountSuccessfullyApplied"));
        setSuccessDisplayed(true);
      } catch (e) {
        if ((e instanceof ResponseError && e.response.status === 422)) {
          setErrorMessage(t("subscriptions.invalidPromotionCode"));
        } else {
          setErrorMessage(t("common.alerts.alert_notification"));
        }
        setErrorDisplayed(true);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      subscriptionOrganization,
      serviceBus,
      setErrorDisplayed,
      setSuccessDisplayed,
      setSuccessMessage,
      setErrorMessage,
      setIsSubmitting,
      promotionCode,
      t
    ]
  );

  if (subscriptionOrganization.subscription.coupon) { return null; }

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        marginTop: 1,
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <DiscountIcon sx={{ color: theme.palette.neutral["600"] }}/>
        <Typography fontWeight={500} color={theme.palette.neutral["600"]} fontSize={18} ml={1}>
          {t("subscriptions.specialDiscount")}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={1}>
        <TextField
          inputProps={{ style: { color: theme.palette.neutral["600"] } }}
          value={promotionCode}
          onChange={(e) => setPromotionCode(e.target.value)}
          size="small"
          placeholder="CODEPROMO"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <LoadingButton variant="outlined" onClick={handleApplySubscriptionDiscount} loading={isSubmitting}>
          {t("subscriptions.applyPromotionCode")}
        </LoadingButton>
      </Box>
    </Paper>
  );
}