import { HealthRecordApi } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class ProAllHealthRecordsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call() {
    const healthRecords = (await this.healthRecordApi.allHealthRecords()).healthRecords;

    this.store.setState((state) => ({
      proHealthRecordIds: healthRecords.map((h) => h.id),
      healthRecords: healthRecords.reduce((acc, healthRecord) => {
        acc[healthRecord.id] = healthRecord;
        return acc;
      }, state.healthRecords ?? {}),
    }));
  }
}
