/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Backdrop, Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { User } from "../../domain/user";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { useAuthenticatedUser } from "../../store";
import { useQueries } from "../providers/Dependencies";
import { useServiceBus } from "../hooks/useServiceBus";
import { v4 as uuidV4 } from "uuid";

export function AccountVerification() {
  return (
    <InnerVerification redirectToIfVerified="/" verified={(user) => user.verified} title="account_validation.title" />
  );
}

export function RppsNumberVerification() {
  return (
    <InnerVerification
      redirectToIfVerified={"/liberal"}
      verified={(user) => !!user.rpps}
      title="account_validation.rpps_verification"
    />
  );
}

export function InnerVerification({
  redirectToIfVerified,
  verified,
  title,
}: {
  redirectToIfVerified: string;
  verified: (u: User) => boolean;
  title: string;
}) {
  const { t } = useI18n();
  const { authStateQuery } = useQueries();
  const currentUser = useAuthenticatedUser();
  const serviceBus = useServiceBus();

  const [_accountLinkingUrl, setAccountLinkingUrl] = useState<URL | undefined>(undefined);

  // Show progress indicator, wait 5 seconds, refresh account and show error
  // message if account is still not verified or rpps is missing
  //
  // We need this because the account linking process is asynchronous and
  // we don't have a way to know when it's done.
  const [refreshingAccount, setRefreshingAccount] = useState(false);
  useEffect(() => {
    (async () => {
      setRefreshingAccount(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await authStateQuery.call();
      setRefreshingAccount(false);
    })();
  }, [authStateQuery]);

  // // Redirect to root if user is already verified
  // useEffect(() => {
  //   if (auth.currentUser && verified(auth.currentUser)) {
  //     navigate(redirectToIfVerified);
  //   }
  // }, [auth.currentUser, auth.currentUser?.verified, navigate, redirectToIfVerified, verified]);

  useEffect(() => {
    (async function () {
      const service = await serviceBus.dispatch({ type: "buildPscAccountLinkingUrl", uuid: uuidV4() });
      if (service.type == "success") {
        setAccountLinkingUrl(service.value);
      }
    })();
  }, [serviceBus]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={refreshingAccount}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <PageLayout title={t(title)}>
        <Paper sx={{ maxWidth: "sm", margin: "auto", padding: { xs: 4, sm: 6 } }}>
          <Alert severity="success">{t("verification.alertMessage")}</Alert>

          <Typography variant="body2" sx={{ my: 2, textAlign: "justify" }}>
            {t("verification.text1")}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2, textAlign: "justify" }}>
            {t("verification.text2")}
          </Typography>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "center", mt: 4 }}>
            <Button
              href={`https://support-cvnpro.mesvaccins.net/hc/fr/requests/new?ticket_form_id=13324704761361&tf_anonymous_requester_email=${
                currentUser?.email
              }${
                currentUser?.mobileNumber ? `&tf_13137831594129=${currentUser?.mobileNumber}` : ""
              }&tf_subject=Demande d’approbation du compte&tf_description=Envoi de la preuve de profession.`}
              target="_blank"
              variant="contained"
              disableElevation
            >
              {t("verification.cta")}
            </Button>
          </Box>
        </Paper>
      </PageLayout>
    </>
  );
}
