import { useCallback, useEffect, useRef, useState } from "react";
import { useQueries } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";

interface UseHealthRecords {
  isLoading: boolean;
  error?: Response | Error;
  fetchHealthRecords: () => void;
}

export function useFetchAllHealthRecords(searchValue?: string): UseHealthRecords {
  const { organizationId, teamId } = useAppContext();

  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const queries = useQueries();
  const fetchingRef = useRef(false);

  const fetchHealthRecords = useCallback(async () => {
    try {
      fetchingRef.current = true;
      setIsLoading(true);
      setError(undefined);
      if (organizationId && teamId) {
        await queries.teamHealthRecordsQuery.call(organizationId, teamId, searchValue);
      } else {
        await queries.proAllHealthRecordsQuery.call();
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        setError(error);
      } else {
        throw error;
      }
    } finally {
      fetchingRef.current = false;
      setIsLoading(false);
    }
  }, [queries, organizationId, teamId, searchValue]);

  useEffect(() => {
    if (fetchingRef.current) return;
    fetchHealthRecords();
  }, [fetchHealthRecords]);

  return { isLoading, error, fetchHealthRecords };
}
