import { Daphne, Disease } from "@syadem/daphne-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { LocaleString } from "../translations";
import { dayjs } from "./dayjs";
import { getTranslatedFieldByLocale } from "./translatedFields";
import { diseaseGroupsFr } from "../domain/VaccinationAct";

function setsAreEqual(setA: Set<string>, setB: Set<string>) {
  if (setA.size !== setB.size) return false;
  for (const item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
}

function findMatchingGroup(diseases: Disease[]) {
  const diseaseNames = new Set(diseases.map((disease) => disease.name.fr || ""));
  for (const group of diseaseGroupsFr) {
    const groupSet = new Set(group.diseases);
    if (setsAreEqual(diseaseNames, groupSet)) {
      return group;
    }
  }
}

function sortDiseasesByName(diseases: Disease[]) {
  return diseases.sort((a, b) => {
    const nameA = a.name.fr?.toLowerCase() || "";
    const nameB = b.name.fr?.toLowerCase() || "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

function sortGroupOfDiseasesByName(diseaseGroups: Record<string, ProVaccinationAct[] | TeamVaccinationAct[]>) {
  const sortedKeys = Object.keys(diseaseGroups).sort();
  const sortedDiseaseGroups: Record<string, ProVaccinationAct[] | TeamVaccinationAct[]> = {};

  for (const key of sortedKeys) {
    sortedDiseaseGroups[key] = diseaseGroups[key];
  }

  return sortedDiseaseGroups;
}

const sortByPerformedOnDates = (
  a: ProVaccinationAct | TeamVaccinationAct,
  b: ProVaccinationAct | TeamVaccinationAct,
) => {
  if (dayjs(a.performedOn).isBefore(dayjs(b.performedOn))) {
    return 1;
  }
  if (dayjs(a.performedOn).isAfter(dayjs(b.performedOn))) {
    return -1;
  }
  return 0;
};

export const groupVaccinationActsByDisease = (
  vaccinationActs: ProVaccinationAct[] | TeamVaccinationAct[],
  daphne: Daphne,
  locale: LocaleString,
): Record<string, ProVaccinationAct[] | TeamVaccinationAct[]> => {
  return vaccinationActs.reduce(
    (acc, vaccinationAct) => {
      const vaccine = daphne.repositories.vaccines.find(vaccinationAct.vaccineId);
      const diseases = daphne.queries.diseasesByVaccine(vaccine);

      if (diseases.length) {
        const matchingGroup = findMatchingGroup(diseases);
        if (matchingGroup) {
          acc[getTranslatedFieldByLocale(locale === "fr" ? "fr" : "en", matchingGroup.groupName)] = [
            ...(acc[getTranslatedFieldByLocale(locale, matchingGroup.groupName)] || []),
            vaccinationAct,
          ];
        } else {
          const sortedDiseaseNames = sortDiseasesByName(diseases)
            .map((disease) => getTranslatedFieldByLocale(locale, disease.name))
            .join(", ");
          acc[sortedDiseaseNames] = [...(acc[sortedDiseaseNames] || []), vaccinationAct].sort(sortByPerformedOnDates);
        }
      }
      return sortGroupOfDiseasesByName(acc);
    },
    {} as Record<string, ProVaccinationAct[] | TeamVaccinationAct[]>,
  );
};
