import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import SmsIcon from "@mui/icons-material/Sms";
import { Alert, Box, Button, Divider, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { QRCodeSVG } from "qrcode.react";
import { useCallback, useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../../components/mui/StyledAccordion";
import { Loading } from "../../../components/shared/Loading";
import { useCopyToClipBoard } from "../../../hooks/useCopyToClipBoard";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { useApis } from "../../../providers/Dependencies";
import { PdfView } from "./PdfView";
import { useAppContext } from "../../../hooks/useAppContext";
import { useServiceBus } from "../../../hooks/useServiceBus";

export function GrantOwnershipForm({ healthRecordId }: { healthRecordId: string | undefined }) {
  const { t } = useI18n();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [didFail, setDidFail] = useState(false);
  const [sms, setSms] = useState<string>("");
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const getToken = async () => {
      if (healthRecordId === undefined) {
        return;
      }
      try {
        // TODO: Wrap this inside a service
        if (organizationId && teamId) {
          const { sharingToken } = await apis.team.recordSharingApi.createSharingToken(
            organizationId,
            teamId,
            healthRecordId,
          );
          const shareUrl = new URL(
            `health-records/redeem-sharing-token?token=${sharingToken}`,
            import.meta.env.VITE_CITIZEN_APP_URL,
          ).toString();
          setShareUrl(shareUrl);
        } else {
          const { sharingToken } = await apis.pro.recordSharingApi.createSharingToken(healthRecordId);
          const shareUrl = new URL(
            `health-records/redeem-sharing-token?token=${sharingToken}`,
            import.meta.env.VITE_CITIZEN_APP_URL,
          ).toString();
          setShareUrl(shareUrl);
        }
      } catch (_e) {
        setDidFail(true);
      }
    };
    getToken();
  }, [apis, healthRecordId, organizationId, teamId]);

  return (
    <Box
      sx={{ mb: 2, backgroundColor: "white", p: { xs: 2, md: 3 }, border: `1px solid ${theme.palette.neutral[300]}` }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: -1 }}>
        <ChevronRightIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
        <Typography variant="h6">{t("share.with_its_owner")}</Typography>
      </Box>
      <Box id="form" sx={{ ml: { xs: 1, md: 3 } }}>
        {isMobile && (isIOS || isAndroid) && renderMobileShare()}
        <EmailSharing healthRecordId={healthRecordId as string} />
        <Divider sx={{ my: 2, color: theme.palette.primary.main }}>{t("share.or")}</Divider>
        <StyledAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} elevation={0}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2">{t("share.other_transfer_methods")}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <QrCodeSharing shareUrl={shareUrl} />

            <Divider sx={{ my: 2, color: theme.palette.primary.main }}>{t("share.or")}</Divider>
            <LinkSharing shareUrl={shareUrl} />
            <Box display="none">
              <Typography>{"It's Hidden"}</Typography>
              {shareUrl ? <QRCodeSVG value={shareUrl} size={200} /> : <Loading />}
            </Box>
            <PdfView id="Pdf" healthRecordId={healthRecordId as string} shareUrl={shareUrl ?? ""} />
          </StyledAccordionDetails>
        </StyledAccordion>
      </Box>
      {didFail && (
        <Alert data-testid="alert" severity="error" sx={{ marginY: 1 }}>
          {t("common.alerts.alert_notification")}
        </Alert>
      )}
    </Box>
  );

  function renderMobileShare() {
    const link = `sms://${sms}${
      isAndroid ? "?" : "&"
    }body=Pour récupérer votre carnet cliquez sur le liens suivant : ${shareUrl}`;
    return (
      <>
        <Typography variant="body2">{t("share.sms_grant_ownership")}</Typography>
        <TextField
          placeholder="sms"
          onChange={(e) => setSms(e.target.value)}
          variant="outlined"
          InputProps={{
            "aria-label": "numéro de téléphone du destinataire",
            startAdornment: (
              <InputAdornment position="start">
                <SmsIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions" href={link}>
                  <SendIcon />
                </IconButton>
              </>
            ),
          }}
          size="small"
          fullWidth
          sx={{ my: 1, width: { xs: "100%", md: "50%" } }}
        />
        <Divider sx={{ my: 2, color: theme.palette.primary.main }}>{t("share.or")}</Divider>
      </>
    );
  }
}

const EmailSharing = ({ healthRecordId }: { healthRecordId: string }) => {
  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean | undefined>(undefined);
  const [emailSentTo, setEmailSentTo] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const { t } = useI18n();
  const { organizationId, teamId } = useAppContext();
  const serviceBus = useServiceBus();

  const sendEmail = useCallback(() => {
    if (validEmail && healthRecordId) {
      try {
        serviceBus.dispatch({
          type: "shareWithCitizenByMail",
          healthRecordId,
          email,
          organizationId,
          teamId,
        });

        setEmailSent(true);
        setEmailSentTo(email);
      } catch (e) {
        console.error(e);
      }
    }
  }, [email, validEmail, serviceBus, healthRecordId, organizationId, teamId]);

  return (
    <Stack>
      <Typography variant="body2">{t("share.email_grant_ownership")}</Typography>
      <TextField
        placeholder="@"
        value={email}
        onChange={(e) => {
          setValidEmail(/^[a-z0-9._\-+]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/i.test(e.target.value));
          setEmail(e.target.value);
        }}
        type={"email"}
        variant="outlined"
        color={email === "" ? "primary" : validEmail ? "success" : "error"}
        InputProps={{
          "aria-label": "Email de partage",
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon color={emailSent ? (validEmail ? "success" : "error") : "inherit"} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color={emailSent ? (validEmail ? "success" : "error") : "primary"}
                sx={{ p: 1 }}
                aria-label="directions"
                onClick={sendEmail}
              >
                <SendIcon />
              </IconButton>
            </>
          ),
        }}
        size="small"
        fullWidth
        sx={{ my: 1, width: { xs: "100%", md: "50%" } }}
      />
      <Typography variant="caption" color={"green"}>
        {emailSent ? t("share.email_sent", { email: emailSentTo }) : null}
      </Typography>
      <Typography variant="body2" color={theme.palette.neutral[500]}>
        {t("share.email_sharing_description")}
      </Typography>
    </Stack>
  );
};

const QrCodeSharing = ({ shareUrl }: { shareUrl: string | null }) => {
  const { t } = useI18n();

  const generatePdf = useCallback(() => {
    const qrCode = document.getElementById("Pdf");
    if (qrCode) {
      const pdfWindow = window.open("pdf", "PRINT");
      if (!pdfWindow) return;
      pdfWindow.document.write(qrCode.innerHTML);
      pdfWindow.document.close();
      pdfWindow.focus();
      pdfWindow.print();
      pdfWindow.close();
    }
  }, []);
  return (
    <>
      <Typography variant="body2">{t("share.qrcode_grant_ownership")}</Typography>

      <Typography variant="body2" color={theme.palette.neutral[500]}>
        {t("share.qrcode_share_description")}
      </Typography>
      <Box id="QrCode" sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", p: 2 }}>
        {shareUrl ? (
          <Stack spacing={2}>
            <QRCodeSVG value={shareUrl} size={150} />{" "}
            <Button variant="outlined" onClick={() => generatePdf()}>
              {t("share.generate_pdf")}
            </Button>
          </Stack>
        ) : (
          <Loading />
        )}
      </Box>
    </>
  );
};

const LinkSharing = ({ shareUrl }: { shareUrl: string | null }) => {
  const { t } = useI18n();
  const [copied, copyToClipBoard] = useCopyToClipBoard(shareUrl ?? "");

  return (
    <>
      <Typography variant="body2">{t("share.grant_ownership_link")}</Typography>
      <Typography variant="body2" color={theme.palette.neutral[500]}>
        {t("share.grant_ownership_link_description")}
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={copied ? t("common.copied") : (shareUrl ?? "")}
        color={copied ? "success" : "primary"}
        InputProps={{
          readOnly: true,
          "aria-label": "Lien de partage",
          endAdornment: (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color={copied ? "success" : "primary"}
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={copyToClipBoard}
              >
                <ContentCopyIcon />
              </IconButton>
            </>
          ),
        }}
        sx={{ my: 2 }}
      />
    </>
  );
};
