import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { SubscriptionStatusEnum } from "@syadem/kairos-subscription-js";
import { groupBy, some } from "lodash-es";
import { JSX, MouseEventHandler, ReactElement, useCallback, useEffect } from "react";
import { Link as RouterLink, useMatch, useResolvedPath } from "react-router-dom";
import logoMesVaccins from "../../../assets/mesVaccinsLogo.png";
import { Organization } from "../../../domain/organization";
import { Team } from "../../../domain/team";
import { User } from "../../../domain/user";
import {
  useAllOrganizations,
  useAuthenticatedUser,
  useSubscriptionOrganization,
  useSubscriptionOrganizations,
  useTeams,
} from "../../../store";
import { useAppContext } from "../../hooks/useAppContext";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { useI18n } from "../../hooks/useI18n";
import { usePendingSharingRequests } from "../../hooks/useSharingRequests";
import { theme } from "../../layout/Theme";
import { MAX_EMAIL_DISPLAY_LENGTH } from "../../pages/account/AccountManagement";
import { useQueries } from "../../providers/Dependencies";
import IconHospital from "../icons/IconHospital";
import IconMedicalTeam from "../icons/IconMedicalTeam";
import IconMedicalTeamRegular from "../icons/IconMedicalTeamRegular";
import { ListItemLink } from "../shared/ListItemLink";
import { OrganizationMenu } from "./OrganizationMenu";

interface MenuListItemProps {
  to: string;
  text: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  selectedMatchPattern?: string;
}

interface OrganizationMenuListProps {
  organization: Organization;
  teams: Team[];
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: -3,
  },
}));

const HeaderMenu = () => {
  return (
    <Box>
      <Link to="/" underline="none" component={RouterLink}>
        <Box sx={{ pr: 1, margin: 2.4, pb: 1.2 }}>
          <img src={logoMesVaccins} width="100%" />
        </Box>
      </Link>
    </Box>
  );
};

const AccountMenu = () => {
  const currentUser = useAuthenticatedUser();
  const { t } = useI18n();
  const subscriptionOrganizations = useSubscriptionOrganizations();

  const hasActiveSubscription =
    subscriptionOrganizations &&
    some(Object.entries(subscriptionOrganizations), ([_, subscriptionOrganization]) => {
      return (
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active ||
        subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing
      );
    });

  return (
    <>
      <Divider sx={{ borderColor: theme.palette.neutral[200] }} />
      <Box
        sx={{
          pl: 1,
          py: 1,
          my: 1,
          display: "flex",
          flexDirection: "column",
          color: theme.palette.neutral[600],
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Badge
            badgeContent={hasActiveSubscription === true ? <AutoAwesomeIcon color="warning" fontSize="small" /> : ""}
            color="default"
          >
            <AccountCircleIcon />
          </Badge>
          {currentUser ? (
            <Box
              sx={{
                pl: 2,
                fontSize: "14px",
                flex: 1,
              }}
            >
              {currentUser.firstName && currentUser.lastName
                ? `${currentUser.firstName} ${currentUser.lastName}`
                : // Limit to 30 characters to avoid overflow
                  currentUser.email.length > MAX_EMAIL_DISPLAY_LENGTH
                  ? `${currentUser.email.substring(0, MAX_EMAIL_DISPLAY_LENGTH - 3)}...`
                  : currentUser.email}
            </Box>
          ) : (
            <Box sx={{ px: 2, width: "100%" }}>
              <Skeleton variant="text" />
            </Box>
          )}
          <Tooltip title={t("common.interface.informations")} placement="right" arrow>
            <Avatar sx={{ backgroundColor: theme.palette.primary[100], mr: 1 }}>
              <IconButton
                component={RouterLink}
                to="/account"
                sx={{
                  color: theme.palette.primary.main,
                  height: "100%",
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                <ManageAccountsIcon />
              </IconButton>
            </Avatar>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={{ borderColor: theme.palette.neutral[200] }} />
    </>
  );
};

function HomeMenuList(props: { currentUser: User }): JSX.Element {
  const { sharingRequests } = usePendingSharingRequests();
  const { t } = useI18n();
  const { currentUser } = props;
  const teams = useTeams();

  return (
    <>
      <MenuListItem
        to="/liberal/health-records"
        text={t("common.interface.patients")}
        icon={<AssignmentIndIcon />}
        disabled={!currentUser?.verified || !currentUser.rpps}
      />
      <MenuListItem
        to="/liberal/unshared-health-records"
        text={t("common.interface.unshared_health_records")}
        icon={<ArchiveIcon />}
        disabled={!currentUser?.verified || !currentUser.rpps}
      />
      <MenuListItem
        to="/liberal/pending-sharing-requests"
        text={t("common.interface.pending_sharing_requests")}
        icon={
          <StyledBadge badgeContent={sharingRequests?.length} color="primary">
            <PersonAddIcon />
          </StyledBadge>
        }
        disabled={!currentUser?.verified || !currentUser.rpps}
      />
      {teams && Object.keys(teams).length > 0 && (
        <MenuListItem
          to="/liberal/health-records-transfer"
          text={t("common.interface.health_records_transfer")}
          icon={<SyncIcon />}
          disabled={!currentUser?.verified || !currentUser.rpps}
        />
      )}
    </>
  );
}

function OrganizationMenuList({ organization, teams }: OrganizationMenuListProps): JSX.Element {
  const { teamId } = useAppContext();
  const currentUser = useAuthenticatedUser();
  const { t } = useI18n();
  const subscriptionOrganization = useSubscriptionOrganization(organization.id);

  const isOrganizationAdmin = currentUser && organization.adminIds?.includes(currentUser.id);

  const isActive =
    subscriptionOrganization &&
    (subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active ||
      subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing);

  const tooltipMessage = useCallback(
    (team: Team) => {
      if (isActive === false) {
        return t("common.interface.subscriptionRequired");
      } else if (!team.activated) {
        return t("common.interface.pendingTeamActivation");
      } else {
        return "";
      }
    },
    [isActive, t],
  );

  return (
    <>
      {teams.map((team) => {
        return (
          <Tooltip title={tooltipMessage(team)} key={team.id} placement="right" arrow>
            <span>
              <MenuListItem
                disabled={!team.activated || isActive === false}
                to={`/organizations/${organization.id}/teams/${team.id}`}
                text={team.presentationName}
                icon={
                  <IconMedicalTeamRegular
                    color={teamId === team.id ? theme.palette.primary.main : organization.color}
                    style={{ height: "16px", width: "auto" }}
                  />
                }
              />
            </span>
          </Tooltip>
        );
      })}
      {isOrganizationAdmin && (
        <>
          <MenuListItem
            to={`/organizations/${organization.id}/settings`}
            text={t("common.interface.organizationSettings")}
            icon={<SettingsIcon style={{ color: organization.color }} />}
            selectedMatchPattern={`/organizations/${organization.id}/settings/*`}
          />
        </>
      )}
    </>
  );
}

function MenuListItem({ to, text, icon, onClick, disabled = false, selectedMatchPattern }: MenuListItemProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({
    path: selectedMatchPattern || resolved.pathname,
    end: selectedMatchPattern ? false : true,
  });
  const selected = match !== null;

  return (
    <ListItemLink disabled={disabled} to={to} onClick={onClick} sx={{ my: 0.5, p: 0 }}>
      <ListItemButton
        disabled={disabled}
        selected={selected}
        sx={{
          color: theme.palette.neutral[600],
          borderRadius: 1,
        }}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItemLink>
  );
}

export function MainMenu(): JSX.Element {
  const { organizationId } = useAppContext();
  const organizations = useAllOrganizations();
  const teams = useTeams();
  const { t } = useI18n();
  const currentUser = useAuthenticatedUser();
  const { allOrganizationsQuery } = useQueries();
  const { subscription } = useCountryConfig();
  const teamsByOrganizationId = groupBy(
    Object.entries(teams || {}).map(([_, t]) => t),
    "organizationId",
  );
  const subscriptionOrganizations = useSubscriptionOrganizations();

  useEffect(() => {
    allOrganizationsQuery.call();
  }, [allOrganizationsQuery]);

  useEffect(() => {
    if (subscription) {
      subscription.queries.subscriptionOrganizationsQuery.call();
    }
  }, [subscription]);

  if (currentUser === undefined) {
    return <></>;
  }

  return (
    <Box sx={{ height: "100%", pl: 1, pr: 2 }}>
      <HeaderMenu />
      <AccountMenu />
      <OrganizationMenu
        title={t("common.interface.my_practice")}
        expanded={!organizationId}
        icon={
          <IconHospital
            color={theme.palette.neutral[600]}
            style={{ height: "20px", width: "auto", marginRight: "16px" }}
          />
        }
        defaultPathname="/liberal/health-records"
      >
        <HomeMenuList currentUser={currentUser} />
      </OrganizationMenu>
      {currentUser.verified && organizations && teams && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Divider sx={{ borderColor: theme.palette.neutral[200], mt: 2 }} />
          <Box sx={{ display: "flex", padding: "0 16px 0 8px", my: 2 }}>
            <IconMedicalTeam color={theme.palette.neutral[600]} style={{ height: "18px", marginRight: "16px" }} />
            <Typography color={theme.palette.neutral[600]} variant="body2" sx={{ fontWeight: "500" }}>
              {t("common.interface.teamAccounts", { smart_count: Object.keys(organizations).length })}
            </Typography>
          </Box>
          <Box sx={{ ml: 2, flex: 1 }}>
            {Object.entries(organizations).map(([orgaId, organization]) => {
              const teams = teamsByOrganizationId[orgaId] || [];
              const activeTeams = teams.filter((t) => t.activated);
              const subscriptionOrganization = subscriptionOrganizations
                ? subscriptionOrganizations[orgaId]
                : undefined;

              const isActive =
                subscriptionOrganization &&
                (subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Active ||
                  subscriptionOrganization.subscription.status == SubscriptionStatusEnum.Trialing);

              const defaultPathname = () => {
                if (isActive === false) {
                  return `/organizations/${organization.id}/settings/subscription`;
                } else if (activeTeams.length > 0) {
                  return `/organizations/${organization.id}/teams/${activeTeams[0].id}/health-records`;
                } else {
                  return `/organizations/${organization.id}`;
                }
              };

              return (
                <OrganizationMenu
                  title={organization.name}
                  expanded={organizationId === organization.id}
                  key={organization.id}
                  organization={organization}
                  icon={
                    <Badge
                      badgeContent={isActive === true ? <AutoAwesomeIcon color="warning" fontSize="small" /> : ""}
                      color="default"
                      sx={{ mr: 2 }}
                    >
                      <WorkspacesIcon
                        fontSize="small"
                        sx={{
                          color: organization.color,
                        }}
                      />
                    </Badge>
                  }
                  defaultPathname={defaultPathname()}
                >
                  <OrganizationMenuList organization={organization} teams={teams} />
                </OrganizationMenu>
              );
            })}
          </Box>
          <Divider sx={{ borderColor: theme.palette.neutral[200], mb: 2 }} />
          <Button
            size="large"
            component={RouterLink}
            to="/organizations/new"
            variant="outlined"
            startIcon={
              <Avatar
                sx={{
                  background: theme.palette.primary[500],
                  width: 20,
                  height: 20,
                }}
              >
                <AddIcon fontSize="small" sx={{ color: "white" }} />
              </Avatar>
            }
            sx={{ py: 1 }}
            fullWidth
          >
            {t("common.interface.newOrganization")}
          </Button>
        </Box>
      )}
      {currentUser.verified && (
        <Button
          size="large"
          component={RouterLink}
          to="/organizations/mentor?reset=true"
          variant="contained"
          fullWidth
          disableElevation
          startIcon={<VerifiedUserIcon />}
          sx={{ mt: 3 }}
        >
          {"Mentor PRO"}
        </Button>
      )}
    </Box>
  );
}
