import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Container, CssBaseline, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { ProfessionalAccount, TeamMember } from "@syadem/kairos-team-js";
import { capitalize } from "lodash-es";
import { JSX, useEffect } from "react";
import { useAuthenticatedUser, useTeamMembers } from "../../../store";
import TeamAdminButton from "../../components/TeamAdminButton";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { ListItemDeleteButton } from "../../components/shared/ListItemDeleteButton";
import { useAppContext } from "../../hooks/useAppContext";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { theme } from "../../layout/Theme";
import { useApis, useQueries } from "../../providers/Dependencies";

interface TeamMembersListProps {
  teamMembers: TeamMember[];
  refreshCallback: () => void;
  deleteMember: (teamMember: TeamMember) => Promise<void>;
}

function TeamMembersList(props: TeamMembersListProps) {
  const { teamMembers, refreshCallback, deleteMember } = props;
  const { t } = useI18n();
  const currentUser = useAuthenticatedUser();

  const showTeamMember = (pro: ProfessionalAccount) => {
    if (pro.firstName && pro.lastName) {
      return <Typography>{`${capitalize(pro.firstName)} ${capitalize(pro.lastName)}`}</Typography>;
    } else if (pro.email) {
      return <Typography>{pro.email}</Typography>;
    } else {
      <></>;
    }
  };

  const sortByRole = (a: TeamMember, b: TeamMember) => {
    if (a.role === b.role) {
      return 0;
    } else if (a.role == "admin") {
      //a sort before b
      return -1;
    } else {
      //a after b
      return 1;
    }
  };

  const renderRow = (teamMember: TeamMember) => {
    return (
      <ListItem
        disablePadding
        key={teamMember.member.id}
        secondaryAction={
          <Stack direction="row">
            <ListItemText
              sx={{
                marginY: "auto",
                mr: 2,
                display: { xs: "none", sm: "block" },
              }}
            />
          </Stack>
        }
        sx={{
          ".MuiListItemSecondaryAction-root": {
            right: { xs: 2, md: 16 },
          },
        }}
      >
        <ListItem
          sx={{
            px: 3,
            py: 2,
            mb: 1,
            border: `solid 1px ${theme.palette.neutral[200]}`,
            borderRadius: 1,
            bgcolor: "background.paper",
          }}
        >
          <ListItemText primary={showTeamMember(teamMember.member)} secondary={t("team.role." + teamMember.role)} />
          {currentUser?.id !== teamMember.member.id && (
            <ListItemDeleteButton
              deleteRecord={() => deleteMember(teamMember)}
              refreshCallback={refreshCallback}
              title={t("common.cta.delete")}
              modalTitle={t("team.member_list_remove")}
              modalContent={
                <>
                  <div>{t("team.member_list_remove_description")}</div>
                </>
              }
              icon={<DeleteOutlineIcon fontSize="small" color="error" />}
              variant="outlined"
              buttonWidth="110px"
              type="button"
            />
          )}
        </ListItem>
      </ListItem>
    );
  };

  return (
    <List sx={{ width: "100%", mb: 2 }}>{teamMembers.sort(sortByRole).map((teamMember) => renderRow(teamMember))}</List>
  );
}

function EmptyPlaceHolder() {
  const { t } = useI18n();
  return <EmptyListPlaceholder title={t("team.no_members")}>{t("team.no_members")}</EmptyListPlaceholder>;
}

export function Members(): JSX.Element {
  const { t } = useI18n();
  const { teamMembersQuery } = useQueries();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const teamMembers = useTeamMembers();

  useEffect(() => {
    if (organizationId && teamId) {
      teamMembersQuery.call({ organizationId, teamId });
    }
  }, [teamMembersQuery, organizationId, teamId]);

  const refreshCallback = () => {
    if (organizationId && teamId) {
      teamMembersQuery.call({ organizationId, teamId });
    }
  };

  const deleteAction = async (teamMember: TeamMember) => {
    if (organizationId && teamId) {
      // TODO: Wrap this inside a service
      await apis.team.teamApi.removeMember(organizationId, teamId, teamMember.member.id);
    }
  };

  return (
    <PageLayout title={t("team.member_list")} actions={<TeamAdminButton />}>
      <Container sx={{ marginTop: "5vh" }}>
        <CssBaseline />
        {teamMembers && teamMembers.length > 0 ? (
          <TeamMembersList teamMembers={teamMembers} refreshCallback={refreshCallback} deleteMember={deleteAction} />
        ) : (
          <EmptyPlaceHolder />
        )}
      </Container>
    </PageLayout>
  );
}
