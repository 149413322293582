import { Alert, Snackbar } from "@mui/material";
import { useAuthenticatedUser, useOrganization, useSubscriptionOrganization } from "../../../../store";
import { useAppContext } from "../../../hooks/useAppContext";
import { SubscriptionSummary } from "../subscription/SubscriptionSummary";
import { PaymentMethod } from "../subscription/PaymentMethod";
import { SubscriptionQuantity } from "../subscription/SubscriptionQuantity";
import { Invoices } from "../subscription/Invoices";
import { PromotionCode } from "../subscription/PromotionCode";
import { useEffect, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { useQueries } from "../../../providers/Dependencies";
import { useCountryConfig } from "../../../hooks/useCountryConfig";
import { PageLayout } from "../../../layout/PageLayout";
import { Box } from "@mui/system";
import { Organization } from "@syadem/kairos-subscription-js";

export interface SubscriptionComponentProps {
  subscriptionOrganization: Organization;
  setErrorDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function OrganizationSubscription() {
  const { t } = useI18n();
  const { organizationId } = useAppContext();
  const subscriptionOrganization = useSubscriptionOrganization(organizationId ?? undefined);
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const { allOrganizationsQuery } = useQueries();
  const { subscription } = useCountryConfig();
  const organization = useOrganization(organizationId ?? undefined);
  const currentUser = useAuthenticatedUser();

  useEffect(() => {
    subscription?.queries.subscriptionOrganizationsQuery.call();
  }, [subscription]);

  useEffect(() => {
    allOrganizationsQuery.call();
  }, [allOrganizationsQuery]);

  if (
    subscriptionOrganization &&
    subscription &&
    subscriptionOrganization.subscription.product.id != subscription.defaultProductId
  ) {
    return (
      <PageLayout title={t("subscriptions.title")}>
        <Alert severity="info">{t("subscriptions.referToSupport")}</Alert>
      </PageLayout>
    );
  }

  if (currentUser && organization && !organization.adminIds?.includes(currentUser.id)) {
    return (
      <PageLayout title={t("subscriptions.title")}>
        <Alert severity="info">{t("subscriptions.referToOrganizationAdmin")}</Alert>
      </PageLayout>
    );
  }

  return (
    // <Box maxWidth="sm">
    <Box>
      {subscriptionOrganization && (
        <>
          <SubscriptionSummary
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <PromotionCode
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <PaymentMethod
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <SubscriptionQuantity
            subscriptionOrganization={subscriptionOrganization}
            setErrorDisplayed={setErrorDisplayed}
            setSuccessDisplayed={setSuccessDisplayed}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
          <Invoices subscriptionOrganization={subscriptionOrganization} />
        </>
      )}
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={successDisplayed}
        autoHideDuration={6000}
        onClose={() => setSuccessDisplayed(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
