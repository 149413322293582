import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Box, Chip, Grid2, Tooltip, Typography } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { ProfileAction, ProfileActionType, ProfileConditionsText, ProfileState } from "@syadem/sad-ui";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { profileCertificationDescription } from "../../../../utils/profileCertificationDescription";
import { useArianeApi, useDaphne } from "../../../hooks";
import { useFetchHealthRecord } from "../../../hooks/useFetchHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { ProfileValidation } from "./ProfileValidation";
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";
import { useHealthRecord } from "../../../../store";

const ProfileConditions = ({ state, dispatch }: { state: ProfileState; dispatch: Dispatch<ProfileAction> }) => {
  const { id } = useParams() as { id: string };
  const { refetch } = useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);
  const { t, locale } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const arianeApi = useArianeApi();
  const daphne = useDaphne();
  const [certificators, setCertificators] = useState<(Professional | Team)[] | undefined>(undefined);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      if (healthRecord) {
        let actCertificators: (Professional | Team)[] = [];

        if (healthRecord.validatorIds.length > 0) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([...new Set(healthRecord.validatorIds)]);
          } catch (_error) {
            actCertificators = [];
          }
        }

        setCertificators(actCertificators);
      }
    })();
  }, [arianeApi?.professionals, healthRecord]);

  const validateProfile = () => {
    dispatch({
      type: ProfileActionType.ValidateProfile,
    });

    // TODO: Wrap this inside a service
    if (organizationId && teamId) {
      return apis.team.healthProfileApi.validateHealthProfile(organizationId, teamId, id);
    } else {
      return apis.pro.healthProfileApi.validateHealthProfile(id);
    }
  };

  const handleUnset = useCallback(
    async (conditionId: string) => {
      // TODO: Wrap this inside a service
      if (organizationId && teamId) {
        await apis.team.healthProfileApi.unsetCondition(organizationId, teamId, id, { conditionId });
      } else {
        await apis.pro.healthProfileApi.unsetCondition(id, { conditionId });
      }
    },
    [apis, organizationId, teamId, id],
  );

  return (
    <>
      <>
        <Grid2 container justifyContent="space-between">
          <Grid2 size={10} pb={2}>
            <Box
              sx={{
                display: "flex",
                width: {
                  xs: "85vw",
                  md: "95%",
                },
              }}
            >
              <Typography fontWeight="bold" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {t("profile.conditionsTitle")}
              </Typography>

              {healthRecord && healthRecord?.validatorIds.length > 0 && certificators && certificators.length > 0 && (
                <Tooltip title={profileCertificationDescription(certificators, healthRecord, t)}>
                  <Chip
                    label={t("profile.validated")}
                    color="success"
                    size="small"
                    sx={{ fontWeight: "500", ml: 1, border: `1px solid ${theme.palette.success[200]}` }}
                    icon={<TaskAltOutlinedIcon fontSize="small" />}
                  />
                </Tooltip>
              )}
            </Box>
          </Grid2>
          <Grid2 size={2} sx={{ textAlign: "right" }}>
            {healthRecord && healthRecord.validatorIds.length === 0 && (
              <ProfileValidation
                validateProfile={validateProfile}
                refreshCallback={refetch}
                healthRecord={healthRecord}
              />
            )}
          </Grid2>
        </Grid2>
        {daphne && (
          <ProfileConditionsText
            profileConditions={state.conditionsProfile}
            daphne={daphne}
            state={state}
            dispatch={dispatch}
            locale={locale}
            unsetCondition={(conditionId) => handleUnset(conditionId)}
            withNestedProfile
            onClickCondition={(conditionId: string) =>
              navigate(`${pathname.endsWith("/") ? pathname.slice(0, -1) : pathname}#${conditionId}`)
            }
          />
        )}
      </>
    </>
  );
};

export default ProfileConditions;
