import { useCallback, useEffect, useRef, useState } from "react";
import { DomainHealthRecord } from "../../domain/HealthRecord";
import { useQueries } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";

export function useFetchHealthRecord(id: string): {
  isLoading: boolean;
  healthRecord?: DomainHealthRecord;
  error?: Response | Error;
  refetch: () => void;
} {
  const { organizationId, teamId } = useAppContext();

  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const queries = useQueries();
  const fetchingRef = useRef(false);

  const fetchHealthRecord = useCallback(async () => {
    try {
      fetchingRef.current = true;
      setIsLoading(true);
      setError(undefined);
      if (organizationId && teamId) {
        await queries.teamHealthRecordQuery.call(organizationId, teamId, id);
      } else {
        await queries.proHealthRecordQuery.call(id);
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        setError(error);
      } else {
        throw error;
      }
    } finally {
      fetchingRef.current = false;
      setIsLoading(false);
    }
  }, [id, organizationId, teamId, queries]);

  useEffect(() => {
    if (fetchingRef.current) return;
    fetchHealthRecord();
  }, [fetchHealthRecord]);

  return {
    error,
    isLoading,
    refetch: fetchHealthRecord,
  };
}
